<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Nombre *</label>
                <input type="text" v-model="Capacitacion.Nombre" placeholder="Nombre" class="form-control">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-12">
                <label for="">Fecha *</label>
                <v-date-picker v-model="Capacitacion.Fecha" :mode="typeCalendar"
					:popover="{
						placement: 'bottom',
						visibility: 'click'
					}"
					:input-props="{
						class: 'form-control cal-black',
						style: 'cursor:pointer;'
					}"
                    :locale="'es-MX'"
				/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-12">
                <label for="">Archivo</label>
                <div class="custom-file-input-image">
                    <input @change="SubirArchivo()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                    <input type="text" v-model="Archivo" class="form-control">
                    <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Comentario</label>
                <textarea class="form-control" v-model="Capacitacion.Comentario" cols="10" rows="10"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Capacitacion",
    props:["poBtnSave","pIdTrabajador"],
    data() {
        return {
            Modal:true,
            FormName:'Capacitacion',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            Capacitacion:{
                IdCapacitacion:0,
                IdTrabajador:0,
                Nombre:'',
                Fecha:'',
                Comentario:'',
                Archivo:'',
            },
            Archivo:'Elegir Archivo (3MB)',
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            let Fecha='';
            if (this.Capacitacion.Fecha!='' && this.Capacitacion.Fecha!=undefined) {
                let day=this.Capacitacion.Fecha.getDate();
                let month=this.Capacitacion.Fecha.getMonth()+1;
                let year=this.Capacitacion.Fecha.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
            let file = this.$refs.file.files[0];
            let formData=new FormData();
            formData.set('IdCapacitacion',this.Capacitacion.IdCapacitacion);
            formData.set('IdTrabajador',this.Capacitacion.IdTrabajador);
            formData.set('Nombre',this.Capacitacion.Nombre);
            formData.set('Comentario',this.Capacitacion.Comentario);
            formData.set('Fecha',Fecha);
            formData.set('NombreArchivo',this.Capacitacion.Archivo);
            formData.append('NombreArchivoFile',file);
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('capacitacion/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $("#Capacitacion").modal("hide");
                this.bus.$emit('ListaPrincipal');
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            });
        },
        Limpiar(){
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';
            this.Capacitacion={
                IdCapacitacion:0,
                IdTrabajador:0,
                Nombre:'',
                Fecha:new Date(),
                Comentario:'',
                Archivo:'',
            }
            // if (localStorage.getItem("fechacalendario")) {
			// 	let NuevaFecha = localStorage.getItem("fechacalendario");
			// 	let formatedDate = NuevaFecha.replace(/-/g, "\/");
			// 	this.Capacitacion.Fecha = new Date(formatedDate);
			// }
            this.Archivo='Elegir Archivo (3MB)';
        },
        Recuperar(){
            this.$http.get('capacitacion/recovery',{
                params:{
                    IdCapacitacion:this.Capacitacion.IdCapacitacion
                }
            }).then((res)=>{
                const objres=res.data.data.capacitacion;
                this.Capacitacion.IdCapacitacion=objres.IdCapacitacion;
                this.Capacitacion.IdTrabajador=objres.IdTrabajador;
                this.Capacitacion.Nombre=objres.Nombre;
                this.Capacitacion.Comentario=objres.Comentario;
                let formatedDate = objres.Fecha.replace(/-/g, "\/");
                this.Capacitacion.Fecha=new Date(formatedDate);
                this.Capacitacion.Archivo=objres.Archivo;
                this.Archivo=objres.Archivo;
            })
        },
        SubirArchivo(){
            const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 3) {
                this.$toast.info('Solo se puede subir archivos menores a 3 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extensiones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.Archivo='Elegir archivo (3MB)';
                return false;
            }

            this.Archivo=image.name;
        }
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id,IdTrabajador)=>{
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (IdTrabajador!==undefined) {
                this.Capacitacion.IdTrabajador=IdTrabajador;
            }
            if (Id > 0) {
				this.Capacitacion.IdCapacitacion = Id;
				this.Recuperar();
			}
        });
    },
    computed:{
        typeCalendar:function(){
            return 'single';
        }
    }
}
</script>

<style>

</style>