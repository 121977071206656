<style>
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
</style>

<template>
	<div>
		<CLoader :pConfigLoad="ConfigLoad">
			<template slot="BodyFormLoad">
				<div class="modal-body form-cotizacion">
					<div class="form-group" v-if="useCheckIn">
						<div class="form-check form-check-inline">
							<div class="checkbox">
								<label>
									<input
										type="checkbox"
										v-model="trabajador.HomeOffice"
										name="optionsCheckboxes"
									/><span class="checkbox-material-green"
										><span class="check"></span
									></span>
									Home Office
								</label>
							</div>
						</div>
						<hr/>
						<br/>
					</div>
					<div class="form-group  row justify-content-center">
						<div class="col-md-12 col-lg-8 grid-r">
							<div class="form-group form-row">
								<div class="col-md-5 col-lg-5">
									<div class="avatar-upload">
										<div class="avatar-edit">
											<input
												id="file"
												ref="file"
												type="file"
												name="myfile"
												@change="uploadImage()"
												accept=".png, .jpg, .jpeg"
											/>
											<label for="file"></label>
										</div>
										<div class="avatar-preview">
											<div
												id="imagePreview"
												style="background-image: url(Img);"
											></div>
										</div>
									</div>
									<label>Recomendada 400 x 400</label>
								</div>
								<div class="col-md-7 col-lg-7">
									<div class="form-group">
										<label>Nombre *</label>
										<input
											type="text"
											v-model="trabajador.Nombre"
											class="form-control"
											placeholder="Nombre"
										/>
										<Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="errorvalidacion.Nombre[0]"></Cvalidation>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<div class="form-group">
												<label for="">Fecha Ingreso *</label>
												<v-date-picker v-model="trabajador.FechaIngreso"
													:popover="{
														placement: 'bottom',
														visibility: 'click'
													}"
													:input-props="{
														class: 'form-control cal-black',
														style: 'cursor:pointer;'
													}"
													:locale="'es-MX'"
												/>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<label for="">Fecha Nacimiento *</label>
												<v-date-picker v-model="trabajador.FechaNacimiento"
													:popover="{
														placement: 'bottom',
														visibility: 'click'
													}"
													:input-props="{
														class: 'form-control cal-black',
														style: 'cursor:pointer;'
													}"
													:locale="'es-MX'"
												/>
											</div>
										</div>
									</div>
									<div class="form-group">
										<label>Posición *</label>
										<select v-model="trabajador.IdCategoria" class="form-control">
											<option value="">--Selecccionar Categoría--</option>
											<option
												:value="lista.IdCategoria"
												v-for="(lista, key, index) in ListaCategoria"
												:key="index"
												>{{ lista.Nombre }}</option
											>
										</select>
										<Cvalidation v-if="this.errorvalidacion.Categoria" :Mensaje="errorvalidacion.Categoria[0]"></Cvalidation>
									</div>
		
									<!-- <div class="form-group">
										<label>Perfil *</label>
										<select v-model="trabajador.IdPerfil" class="form-control">
											<option :value="''">--Seleccionar Perfil--</option>
											<option
												:value="item.IdPerfil"
												v-for="(item, index) in ListaPerfil"
												:key="index"
												>{{ item.Nombre }}</option
											>
										</select>
										<Cvalidation v-if="this.errorvalidacion.Perfil" :Mensaje="errorvalidacion.Perfil[0]"></Cvalidation>
									</div> -->
								</div>
							</div>
							<div class="form-group form-row">
								<div class="col-lg-6">
									<div class="form-group">
										<label>Perfil *</label>
										<select @change="SeleccionPerfil" v-model="trabajador.IdPerfil" class="form-control">
											<option :value="''">--Seleccionar Perfil--</option>
											<option
												:value="item.IdPerfil"
												v-for="(item, index) in ListaPerfil"
												:key="index"
												>{{ item.Nombre }}</option
											>
										</select>
										<Cvalidation v-if="this.errorvalidacion.Perfil" :Mensaje="errorvalidacion.Perfil[0]"></Cvalidation>
									</div>
								</div>
								<div class="col-md-6 col-lg-6">
									<div class="form-group">
										<label>Profesión *</label>
										<input
											v-model="trabajador.Profesion"
											type="text"
											class="form-control"
											placeholder="Profesión"
										/>
										<Cvalidation v-if="this.errorvalidacion.Profesion" :Mensaje="errorvalidacion.Profesion[0]"></Cvalidation>
									</div>
								</div>
							</div>
							<div class="form-group form-row">
								<div class="col-md-6 col-lg-6">
									<div class="form-group">
										<label>Teléfono *</label>
										<input
											type="text"
											v-model="trabajador.Telefono"
											class="form-control"
											placeholder="Teléfono"
										/>
										<Cvalidation
											v-if="this.errorvalidacion.Telefono"
											:Mensaje="errorvalidacion.Telefono[0]"
										></Cvalidation>
									</div>
								</div>
								<div class="col-md-6 col-lg-6">
									<div class="form-group">
										<label>Correo *</label>
										<input
											type="text"
											:readonly="Readonly"
											v-model="trabajador.Correo"
											class="form-control"
											placeholder="Ejem. email@email.com"
										/>
										<Cvalidation
											v-if="this.errorvalidacion.Correo"
											:Mensaje="errorvalidacion.Correo[0]"
										></Cvalidation>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12 col-lg-12 d-none d-md-block d-lg-none">
							<hr />
						</div>
						<div class="col-md-6 col-lg-4">
							<div class=" form-group text-center mt-2">
								<button
									type="button"
									@click="get_calculadora"
									data-toggle="modal"
									data-target="#ModalCalculadora"
									data-backdrop="static"
									class="btn btn-01 cal"
								>
									Calculadora
								</button>
							</div>
		
							<div class="form-group form-row">
								<div class="col-md-6 col-lg-6">
									<label class="small">Mano de Obra / Hora *</label>
									<vue-numeric
										:minus="false"
										class="form-control  "
										currency=""
										separator=","
										:precision="0"
										v-model="trabajador.CostoHora"
									></vue-numeric>
									<Cvalidation
										v-if="this.errorvalidacion.CostoHora"
										:Mensaje="'Campo obligatorio'"
									></Cvalidation>
								</div>
		
								<div class="col-md-6 col-lg-6">
									<label class="small">Burden/Hrs. *</label>
									<vue-numeric
										:minus="false"
										class="form-control  "
										currency=""
										separator=","
										:precision="0"
										v-model="trabajador.CostoAnual"
									></vue-numeric>
									<Cvalidation
										v-if="this.errorvalidacion.CostoAnual"
										:Mensaje="'Campo obligatorio'"
									></Cvalidation>
								</div>
							</div>
							<div class="form-group form-row">
								<div class="col-md-6 col-lg-6">
									<label class="small">Hors. Trab./Semanal *</label>
									<vue-numeric
										:minus="false"
										class="form-control  "
										currency=""
										separator=","
										:precision="0"
										v-model="trabajador.HorasTS"
									></vue-numeric>
									<Cvalidation
										v-if="this.errorvalidacion.HorasTS"
										:Mensaje="'Campo obligatorio'"
									></Cvalidation>
								</div>
								<div class="col-md-6 col-lg-6 mr-0 pr-0">
									<label class="small m-0 p-0">Hrs. Produc./Semanal *</label>
									<vue-numeric
										:minus="false"
										class="form-control  "
										currency=""
										separator=","
										:precision="0"
										v-model="trabajador.HorasPS"
									></vue-numeric>
									<Cvalidation
										v-if="this.errorvalidacion.HorasPS"
										:Mensaje="'Campo obligatorio'"
									></Cvalidation>
								</div>
							</div>
							<div class="form-group form-row mt-2">
								<div class="col-lg-12">
									<div v-if="useCheckIn" class="form-check form-check-inline">
										<div class="checkbox">
											<label>
												<span class="mr-1" style="font-size:13px;">Módulo Check-In</span>
												<input
													:disabled="DisableCheckIn"
													type="checkbox"
													v-model="trabajador.AccesoApp"
													name="optionsCheckboxes"
												/><span class="checkbox-material-green"
													><span class="check"></span
												></span>
											</label>
										</div>
									</div>
								</div>
								<Cvalidation v-if="this.errorvalidacion.AccesoApp" :Mensaje="'Campo obligatorio'"></Cvalidation>
							</div>
							<div v-show="this.trabajador.AccesoApp" class="form-group row">
								<div class="col-lg-12">
									<h4 class="titulo-04">Horarios Laborales</h4>
								</div>
							</div>
							<div v-show="this.trabajador.AccesoApp" class="form-group row">
								<div class="col-lg-6">
									<label class="small m-0 p-0">Check-In (Hora)</label>
									<div class="row">
										<div class="col-lg-6 pr-0">
											<select name="" id="" v-model="trabajador.HoraEntrada" class="form-control form-control-sm mr-0 pr-0">
												<option v-for="n in 25" :key="n" :value="n-1<10 ? '0'+Number(n-1) : n-1">{{ n-1<10 ? '0'+Number(n-1) : n-1 }}</option>
											</select>
											<!-- <input @blur="HoraInput($event,'entrada')" v-model="trabajador.HoraEntrada" type="text" class="form-control"/> -->
										</div>
										<div class="col-lg-6 pr-0">
											<select name="" id="" v-model="trabajador.MinutoEntrada" class="form-control form-control-sm mr-0 pr-0">
												<option v-for="n in 60" :key="n" :value="n-1<10 ? '0'+Number(n-1) : n-1">{{ n-1<10 ? '0'+Number(n-1) : n-1 }}</option>
											</select>
											<!-- <input @blur="MinutoInput($event,'entrada')" v-model="trabajador.MinutoEntrada" type="text" class="form-control"/> -->
										</div>
									</div>
									<!-- <h4 class="titulo-04">Horario Laboral</h4> -->
									<Cvalidation v-if="this.errorvalidacion.HoraEntrada" :Mensaje="this.errorvalidacion.HoraEntrada[0]"></Cvalidation>
									<br v-if="this.errorvalidacion.HoraEntrada"/>
									<Cvalidation v-if="this.errorvalidacion.MinutoEntrada" :Mensaje="this.errorvalidacion.MinutoEntrada[0]"></Cvalidation>
								</div>
								<div class="col-lg-6">
									<label class="small m-0 p-0">Check-Out (Hora)</label>
									<div class="row">
										<div class="col-lg-6 pr-0">
											<!-- <input @blur="HoraInput($event,'salida')" v-model="trabajador.HoraSalida" type="text" class="form-control"/> -->
											<select name="" id="" v-model="trabajador.HoraSalida" class="form-control form-control-sm mr-0 pr-0">
												<option v-for="n in 25" :key="n" :value="n-1<10 ? '0'+Number(n-1) : n-1">{{ n-1<10 ? '0'+Number(n-1) : n-1 }}</option>
											</select>
										</div>
										<div class="col-lg-6 pr-0">
											<select name="" id="" v-model="trabajador.MinutoSalida" class="form-control form-control-sm mr-0 pr-0">
												<option v-for="n in 60" :key="n" :value="n-1<10 ? '0'+Number(n-1) : n-1">{{ n-1<10 ? '0'+Number(n-1) : n-1 }}</option>
											</select>
											<!-- <input @blur="MinutoInput($event,'salida')" v-model="trabajador.MinutoSalida" type="text" class="form-control"/> -->
										</div>
									</div>
									<!-- <h4 class="titulo-04">Horario Laboral</h4> -->
									<Cvalidation v-if="this.errorvalidacion.HoraSalida" :Mensaje="this.errorvalidacion.HoraSalida[0]"></Cvalidation>
									<br v-if="this.errorvalidacion.HoraSalida"/>
									<Cvalidation v-if="this.errorvalidacion.MinutoSalida" :Mensaje="this.errorvalidacion.MinutoSalida[0]"></Cvalidation>
								</div>
							</div>
							<div v-show="this.trabajador.AccesoApp" class="form-group row mt-2">
								<div class="col-lg-6">
									<label class="small m-0 p-0">Tiempo de Comida (Minutos)</label>
									<!-- <input v-model="trabajador.MinutoComida" type="number" min="0" max="90" class="form-control"> -->
									<vue-numeric :min="0" :max="90" :minus="false" class="form-control" currency="" separator="," :precision="0" v-model="trabajador.MinutoComida"></vue-numeric>
								</div>
								<!-- <div class="col-lg-6 pr-0 mt-1">
									<br>
									<div class="form-check form-check-inline">
										<div class="checkbox">
											<label>
												<span class="mr-1" style="font-size:13px;">Horas Extra</span>
												<input
													type="checkbox"
													v-model="trabajador.AutHoraExtra"
													name="optionsCheckboxes"
												/><span class="checkbox-material-green"
													><span class="check"></span
												></span>
											</label>
										</div>
									</div>
								</div> -->
							</div>
						</div>
						<div class="col-md-12 col-lg-12">
							<hr />
						</div>
					</div>
					<div v-if="trabajador.IdTrabajador == 0" class="form-group form-row">
						<div class="col-md-6 col-lg-6">
							<label>Usuario</label>
							<input
								type="text"
								readonly
								v-model="trabajador.Correo"
								class="form-control"
								placeholder=""
							/>
							<Cvalidation
								v-if="this.errorvalidacion.Usuario"
								:Mensaje="errorvalidacion.Usuario[0]"
							></Cvalidation>
						</div>
						<div class="col-md-3 col-lg-3">
							<label>Contraseña *</label>
							<input
								v-model="trabajador.Pass"
								type="password"
								class="form-control"
								placeholder=""
							/>
							<Cvalidation
								v-if="this.errorvalidacion.Pass"
								:Mensaje="'Campo obligatorio'"
							></Cvalidation>
						</div>
						<div class="col-md-3 col-lg-3">
							<label>Confirmar Contraseña *</label>
							<input
								type="password"
								v-model="trabajador.Pass2"
								class="form-control"
								placeholder=""
							/>
							<Cvalidation
								v-if="this.errorvalidacion.Password_Confirmacion"
								:Mensaje="'Campo obligatorio'"
							></Cvalidation>
						</div>
					</div>
					<div class="form-group form-row">
						<div class="col-md-12 col-lg-12">
							<label>Comentarios</label>
							<textarea
								class="form-control"
								v-model="trabajador.Observaciones"
								rows="2"
								placeholder="Escribir Comentario..."
							></textarea>
						</div>
						<!-- <div class="col-md-6 col-lg-6">
							<label>Inventario</label>
							<textarea
								class="form-control"
								v-model="trabajador.Inventario"
								rows="2"
								placeholder="Escribir Inventario..."
							></textarea>
						</div> -->
					</div>
				</div>
			</template>
		</CLoader>
		

		<Modal
			:TipoM="2"
			:size="'modal-sm'"
			:Showbutton="false"
			:NameModal="'ModalCalculadora'"
			:Nombre="'Calculadora'"
		>
			<template slot="Form">
				<Calculo :Calculadora="Calculadora" :trabajador="trabajador"></Calculo>
			</template>
		</Modal>
	</div>
</template>
<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import $$ from "jquery";
import Cbtnsave from "@/components/Cbtnsave.vue";
import Cvalidation from "@/components/Cvalidation.vue";
import Modal from "@/components/Cmodal.vue";
import ImagenDefault from "@/images/foto.png";

import Calculo from "@/views/catalogos/personal/Calculo.vue";
import CLoader from "@/components/CLoader.vue";
export default {
	name: "Form",
	props: ["IdTrabajador", "poBtnSave"],
	data() {
		return {
			Modal: true, //Sirve pra los botones de guardado
			FormName: "Form Personal", //Sirve para donde va regresar
			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
			ListaCategoria: [],
			errorvalidacion: [],
			previewImage: null,
			trabajador: {
				IdTrabajador: 0,
				Nombre: "",
				Telefono: "",
				Profesion: "",
				Categoria: "",
				CostoHora: "",
				HorasTS: "",
				HorasPS: "",
				CostoAnual: "",
				IdSucursal: "",
				Usuario: "",
				Pass: "",
				Observaciones: "",
				Perfil: "",
				IdCategoria: "",
				IdRol: "",
				IdUsuario: "",
				Correo: "",
				Estatus: "",
				Token: "",
				EstadoChat: "",
				IdTipoProceso: "",
				UpdateApp: "",
				GastoAsignado: "",
				IdCajaC: "",
				Inventario: "",
				Imagen: "",
				Foto2: "",
				IdPerfil: "",
				Pass2: "",
				AccesoApp:false,
				HoraEntrada:'',
				MinutoEntrada:'',
				HoraSalida:'',
				MinutoSalida:'',
				MinutoComida:'',
				AutHoraExtra:false,
				SueldoAnualIntegrado:'',
				FechaIngreso:'',
				FechaNacimiento:'',
				HomeOffice:false
			},
			urlApi: "trabajador/recovery",
			urlApiCategoria: "categoriapersonal/get",
			Img: null,
			ListaPerfil: [],
			Calculadora: { MO: "", HTS: "", HPS: "" },
			Readonly: false,
			UsuariosCheckIn:0,
			ContadorCheckIn:0,
			DisableCheckIn:false,
			useCheckIn: false,
			PaqueteUsuarios:0,
			ContadorUsuarios:0
		};
	},
	components: {
		Cbtnsave,
		Cvalidation,
		Modal,
		Calculo,
		CLoader
	},
	methods: {
		uploadImage() {
			this.trabajador.Imagen = this.$refs.file.files[0];
			const image = this.$refs.file.files[0];
			const reader = new FileReader();
			var img = "";
			reader.readAsDataURL(image);

			reader.onload = e => {
				this.Img = e.target.result;
				this.readURL(this.Img);
			};
		},
		cargarImagen() {},
		listaCategoria() {
			this.$http
				.get(this.urlApiCategoria, {
					params: { RegEstatus: "A" }
				})
				.then(res => {
					this.ListaCategoria = res.data.data.categoriapersonal;
				});
		},
		async Guardar() {
			let usuario = "";
			let correo = "";
			if (this.trabajador.Usuario != "") {
				usuario = this.trabajador.Usuario.trim();
			}
			if (this.trabajador.Correo != "") {
				correo = this.trabajador.Correo.trim();
			}
			//deshabilita botones
			this.poBtnSave.toast = 0;
			this.poBtnSave.disableBtn = true;
			let Fecha='';
			let FechaNacimiento='';
            if (this.trabajador.FechaIngreso!='' && this.trabajador.FechaIngreso!=undefined) {
                let day=this.trabajador.FechaIngreso.getDate();
                let month=this.trabajador.FechaIngreso.getMonth()+1;
                let year=this.trabajador.FechaIngreso.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
			if (this.trabajador.FechaNacimiento!='' && this.trabajador.FechaNacimiento!=undefined) {
                let day=this.trabajador.FechaNacimiento.getDate();
                let month=this.trabajador.FechaNacimiento.getMonth()+1;
                let year=this.trabajador.FechaNacimiento.getFullYear();
                FechaNacimiento=year+'-'+month+'-'+day;   
            }

			let formData = new FormData();

			formData.set("IdTrabajador", this.trabajador.IdTrabajador);
			formData.set("Nombre", this.trabajador.Nombre);
			formData.set("Telefono", this.trabajador.Telefono);
			formData.set("Profesion", this.trabajador.Profesion);
			formData.set("Categoria", this.trabajador.Categoria);
			formData.set("CostoHora", this.trabajador.CostoHora);
			formData.set("CostoAnual", this.trabajador.CostoAnual);
			formData.set("IdSucursal", this.trabajador.IdSucursal);
			formData.set("Usuario", usuario);
			formData.set("Pass", this.trabajador.Pass);
			formData.set("Pass2", this.trabajador.Pass2);
			formData.set("Observaciones", this.trabajador.Observaciones);
			formData.set("Perfil", this.trabajador.Perfil);
			formData.set("HorasTS", this.trabajador.HorasTS);
			formData.set("HorasPS", this.trabajador.HorasPS);
			formData.set("IdCategoria", this.trabajador.IdCategoria);
			formData.set("IdPerfil", this.trabajador.IdPerfil);
			formData.set("IdUsuario", this.trabajador.IdUsuario);
			formData.set("Correo", correo);
			formData.set("Estatus", this.trabajador.Estatus);
			formData.set("Token", this.trabajador.Token);
			formData.set("EstadoChat", this.trabajador.EstadoChat);
			formData.set("IdTipoProceso", this.trabajador.IdTipoProceso);
			formData.set("UpdateApp", this.trabajador.UpdateApp);
			formData.set("GastoAsignado", this.trabajador.GastoAsignado);
			formData.set("IdCajaC", this.trabajador.IdCajaC);
			formData.set("Inventario", this.trabajador.Inventario);
			formData.set("NombreFoto", this.trabajador.Foto2);
			formData.set("AccesoApp", this.trabajador.AccesoApp ? 1 : 0);
			formData.set("AutHoraExtra", this.trabajador.AutHoraExtra ? 1 : 0);
			formData.set("HoraEntrada", this.trabajador.HoraEntrada);
			formData.set("MinutoEntrada", this.trabajador.MinutoEntrada);
			formData.set("HoraSalida", this.trabajador.HoraSalida);
			formData.set("MinutoSalida", this.trabajador.MinutoSalida);
			formData.set("MinutoComida", this.trabajador.MinutoComida);
			formData.set("SueldoAnualIntegrado", this.trabajador.SueldoAnualIntegrado);
			formData.set("FechaIngreso",Fecha);
			formData.set("FechaNacimiento",FechaNacimiento);
			formData.set("HomeOffice", this.trabajador.HomeOffice ? 1 : 0);
			let file = this.$refs.file.files[0];
			formData.append("File", file);

			await this.$http
				.post("trabajador/post", formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					this.poBtnSave.disableBtn = false;
					this.poBtnSave.toast = 1;

					$("#ModalForm").modal("hide");
					this.bus.$emit("List");
				})
				.catch(err => {
					this.poBtnSave.disableBtn = false;
					this.poBtnSave.toast = 2;
					this.errorvalidacion = err.response.data.message.errores;
				});
		},
		Limpiar() {
			this.Img = "";
			this.trabajador = {
				IdTrabajador: 0,
				Nombre: "",
				Telefono: "",
				Profesion: "",
				Categoria: "",
				CostoHora: "",
				HorasTS: "",
				HorasPS: "",
				CostoAnual: "",
				IdSucursal: "",
				Usuario: "",
				Pass: "",
				Observaciones: "",
				Perfil: "",
				IdCategoria: "",
				IdRol: "",
				IdUsuario: "",
				Correo: "",
				Estatus: "",
				Token: "",
				EstadoChat: "",
				IdTipoProceso: "",
				UpdateApp: "",
				GastoAsignado: "",
				IdCajaC: "",
				Inventario: "",
				Imagen: "",
				Foto2: "",
				IdPerfil: "",
				Pass2: "",
				AccesoApp:false,
				HoraEntrada:'',
				MinutoEntrada:'',
				HoraSalida:'',
				MinutoSalida:'',
				MinutoComida:'',
				AutHoraExtra:false,
				SueldoAnualIntegrado:'',
				FechaIngreso:new Date(),
				FechaNacimiento:new Date(),
				HomeOffice:false
			};

			// (this.trabajador.FechaIngreso = "");
			// if (localStorage.getItem("fechacalendario")) {
			// 	let NuevaFecha = localStorage.getItem("fechacalendario");
			// 	let formatedDate = NuevaFecha.replace(/-/g, "\/");
			// 	this.trabajador.FechaIngreso = new Date(formatedDate);
			// 	this.trabajador.FechaNacimiento = new Date(formatedDate);
			// }
			this.trabajador.Imagen2 = "";
			this.errorvalidacion = [""];
			//const input = this.$refs.file;
			//(input.type = "text"), (input.type = "file");
			this.readURL(this.Img);
			this.Readonly = false;
		},

		get_one() {
			if(this.trabajador.IdTrabajador != undefined && this.trabajador.IdTrabajador != null && this.trabajador.IdTrabajador > 0) {

				this.$http.get(this.urlApi, {
					params: { 
						IdTrabajador: this.trabajador.IdTrabajador 
					}
				}).then(res => {
					this.trabajador = res.data.data.trabajador;
					this.trabajador.HoraEntrada=this.trabajador.HoraEntrada.length==1 ? '0'+this.trabajador.HoraEntrada : this.trabajador.HoraEntrada;
					this.trabajador.MinutoEntrada=this.trabajador.MinutoEntrada.length==1 ? '0'+this.trabajador.MinutoEntrada : this.trabajador.MinutoEntrada;
					this.trabajador.HoraSalida=this.trabajador.HoraSalida.length==1 ? '0'+this.trabajador.HoraSalida : this.trabajador.HoraSalida;
					this.trabajador.MinutoSalida=this.trabajador.MinutoSalida.length==1 ? '0'+this.trabajador.MinutoSalida : this.trabajador.MinutoSalida; 
					this.trabajador.AccesoApp=res.data.data.trabajador.AccesoApp==0 ? false : true;
					this.trabajador.AutHoraExtra=res.data.data.trabajador.AutHoraExtra==0 ? false : true;
					this.trabajador.HomeOffice=res.data.data.trabajador.HomeOffice==0 ? false : true;
					this.trabajador.Pass2 = "";

					let imagn = res.data.data.trabajador.Imagen;
					let Fotgn = res.data.data.trabajador.Foto;
					let Fot2gn = res.data.data.trabajador.Foto2;
					let defImg = "";

					if (imagn != "" && imagn != null) {
						this.Img = "data:image/png;base64," + imagn;
					} else if (Fotgn != "" && Fotgn != null) {
						this.Img = res.data.data.UrlFoto + this.trabajador.Foto2;
					} else if (Fot2gn != "" && Fot2gn != null) {
						this.Img = res.data.data.UrlFoto + this.trabajador.Foto2;
					} else {
						this.Img = ImagenDefault;
					}

					//this.Img= res.data.data.UrlFoto+ this.trabajador.Foto2;
					this.readURL(this.Img);

					if (this.trabajador.IdTrabajador > 0) {
						this.Readonly = true;
					}
					if (this.trabajador.AccesoApp) {
						this.DisableCheckIn=false;
					}

					if(this.trabajador.FechaIngreso !== '' && this.trabajador.FechaIngreso != null){
						let formatedDate = this.trabajador.FechaIngreso.replace(/-/g,'\/');
						this.trabajador.FechaIngreso=new Date(formatedDate);
					}
					if(this.trabajador.FechaNacimiento !== '' && this.trabajador.FechaNacimiento != null){
						let formatedDate = this.trabajador.FechaNacimiento.replace(/-/g,'\/');
						this.trabajador.FechaNacimiento=new Date(formatedDate);
					}
					this.get_perfil();
						
				});
			}
			else{
				this.get_perfil();
			}
		},
		get_calculadora() {
			//this.$emit('titulomodal','Calculadora');
		},
		get_perfil() {
			this.$http
				.get("perfil/get", {
					params: {}
				})
				.then(res => {
					this.ListaPerfil = res.data.data.perfil;
					this.ListaPerfil.shift(); //shift() remueve el primer elemento del array
					// this.GetUsuariosCheckIn();
					if (this.useCheckIn) {
						if (this.trabajador.IdTrabajador>0) {
							if (this.trabajador.IdPerfil==11 && this.ContadorUsuarios>=this.PaqueteUsuarios) {
								this.ListaPerfil=this.ListaPerfil.filter((element)=>element.Nombre=='Check-In');
							}
							if (this.trabajador.IdPerfil!=11 && this.DisableCheckIn) {
								this.ListaPerfil.pop();
							}
						}
						else{
							if (this.ContadorUsuarios>=this.PaqueteUsuarios) {
								this.ListaPerfil=this.ListaPerfil.filter((element)=>{
									if (element.Nombre=='Check-In') {
										return true;
									}
								});
							}
							if (this.DisableCheckIn) {
								this.ListaPerfil.pop();
							}
						}
					}
					else{
						this.ListaPerfil.pop();
					}
					// this.get_one();
					this.ConfigLoad.ShowLoader=false;
				});
		},

		GetUsuariosCheckIn() {
			this.ConfigLoad.ShowLoader=true;
			if(this.useCheckIn) { // si la empresa no cuenta con el modulo, no se ejecuta la API

				this.$http.get('sucursal/paquetecheckin')
				.then((res)=>{
					this.PaqueteUsuarios=parseInt(res.data.data.PaqueteUsuarios.data.PaqueteU);
					this.ContadorUsuarios=parseInt(res.data.data.ContadorUsuarios.data.Quantity);
					this.UsuariosCheckIn=res.data.data.PaqueteCheckIn=='' ? 0 : parseInt(res.data.data.PaqueteCheckIn);
					this.ContadorCheckIn=parseInt(res.data.data.ContadorCheckIn);
					if (this.ContadorCheckIn>=this.UsuariosCheckIn) {
						this.DisableCheckIn=true;
					}
					else{
						this.DisableCheckIn=false;
					}

				}).finally(() => {
					this.get_one();
				});

			}else {
				this.get_one();
			}
			
		},
		readURL(input) {
			$$("#imagePreview").css("background-image", "url(" + this.Img + ")");
			$$("#imagePreview").hide();
			$$("#imagePreview").fadeIn(650);
		},
		HoraInput(e,Cadena){
			if (e.target.value!='') {
				var regex = new RegExp("^[0-9]+$");
				if (isNaN(e.target.value) || !regex.test(e.target.value)) {
					e.target.value='00';
					this.$toast.warning('Sólo se Aceptan Números en Este Campo');
					this.AsignarValorHora(Cadena,e.target.value);
					return false;
				}
				if (e.target.value=='0') {
					e.target.value='00';
					this.AsignarValorHora(Cadena,e.target.value);
					return false;
				}
				if (e.target.value.length>2) {
					e.target.value='00';
					this.$toast.warning('Sólo Pueden Haber 2 Dígitos en Este Campo');
					this.AsignarValorHora(Cadena,e.target.value);
					return false;
				}
				let PrimerValor=e.target.value.substr(0,1);
				if (PrimerValor!='0') {
					if (Number(e.target.value)>23) {
						e.target.value='00';
						this.$toast.warning('Sólo se Admiten Números del 0 al 23 Este Campo');
						this.AsignarValorHora(Cadena,e.target.value);
						return false;
					}
					if (e.target.value.length<2) {
						e.target.value='0'+e.target.value;
					}
				}
			}
			this.AsignarValorHora(Cadena,e.target.value);
			// switch (Cadena) {
			// 	case 'entrada':
			// 		this.trabajador.HoraEntrada=e.target.value;
			// 		break;
			
			// 	case 'salida':
			// 		this.trabajador.HoraSalida=e.target.value
			// 		break;
			// }
		},
		AsignarValorHora(Cadena,Valor){
			switch (Cadena) {
				case 'entrada':
					this.trabajador.HoraEntrada=Valor;
					break;
			
				case 'salida':
					this.trabajador.HoraSalida=Valor;
					break;
			}
		},
		MinutoInput(e,Cadena){
			if (e.target.value!='') {
				var regex = new RegExp("^[0-9]+$");
				if (isNaN(e.target.value) || !regex.test(e.target.value)) {
					e.target.value='00';
					this.$toast.warning('Sólo se Aceptan Números en Este Campo');
					this.AsignarValorMinuto(Cadena,e.target.value);
					return false;
				}
				if (e.target.value=='0') {
					e.target.value='00';
					this.AsignarValorMinuto(Cadena,e.target.value);
					return false;
				}
				if (e.target.value.length>2) {
					e.target.value='00';
					this.$toast.warning('Sólo Pueden Haber 2 Dígitos en Este Campo');
					this.AsignarValorMinuto(Cadena,e.target.value);
					return false;
				}
				let PrimerValor=e.target.value.substr(0,1);
				if (PrimerValor!='0') {
					if (Number(e.target.value)>59) {
						e.target.value='00';
						this.$toast.warning('Sólo se Admiten Números del 0 al 59 Este Campo');
						this.AsignarValorMinuto(Cadena,e.target.value);
						return false;
					}
					if (e.target.value.length<2) {
						e.target.value='0'+e.target.value;
					}
				}
			}
			this.AsignarValorMinuto(Cadena,e.target.value);
		},
		AsignarValorMinuto(Cadena,Valor){
			switch (Cadena) {
				case 'entrada':
					this.trabajador.MinutoEntrada=Valor;
					break;
			
				case 'salida':
					this.trabajador.MinutoSalida=Valor;
					break;
			}
		},
		SeleccionPerfil(){
			if (this.trabajador.IdPerfil==11) {
				this.trabajador.AccesoApp=true;
			}
		}
	},
	created() {
		let user = JSON.parse(sessionStorage.getItem("user"));
		let Version=sessionStorage.getItem("version");
		if(user.hasOwnProperty('listaPaquetesPrincipal')) {
			let index = user.listaPaquetesPrincipal.findIndex((element)=>element.Paquete=='Check-In'); // La Empresa cuenta con el modulo de checkIn?
			if(index != -1){
				this.useCheckIn = true;
			}
		}
		if (Version=='checkin') {
			this.useCheckIn=true;
		}
		this.Limpiar();
		this.bus.$off("Nuevo");
		this.bus.$off("GetCalculo");
		this.listaCategoria();
		// this.get_perfil();

		this.bus.$on("GetCalculo", obj => {
			this.get_datacalculo(obj);
		});

		//Este es para modal
		this.bus.$on("Nuevo", (data, Id) => {
			this.DisableCheckIn=false;
			this.Limpiar();
			
			if (Id != undefined && parseInt(Id) > 0) {
				console.log(Id);
				this.trabajador.IdTrabajador = Id; 
			}
			// this.get_perfil();
			this.GetUsuariosCheckIn();
			this.poBtnSave.disableBtn = false;

			this.bus.$off("Save");
			this.bus.$on("Save", () => {
				this.Guardar();
			});

			// if (Id > 0) {
			// 	this.trabajador.IdTrabajador = Id;
			// 	this.get_one();
			// }
		});
		// if (this.Id != undefined) {
		// 	this.trabajador.IdTrabajador = this.Id;
		// 	this.get_one();
		// }
	},
	computed: {}
};
</script>
