<template>
	<div>
		<section class="container-fluid mt-2">
			<Menu :pSitio="NombreSeccion" :pTxtDefault="TxtDefault" :pShowNext="ShowNext">
				<template slot="BtnInicio">
					<!-- <div class="mr-2 mt-2 mb-2">
						<button type="button" data-toggle="modal" data-target="#ModalForm"  data-backdrop="static" data-keyboard="false" class="btn btn-pink mr-2" @click="Nuevo">Nueva Actividad</button>
					</div> -->

				</template>
            </Menu>


			<div class="row mt-2">
				<div class="col-md-12 col-lg-9 col-xl-9">
					<div class="card">
						<CLoader :pConfigLoad="ConfigLoadCalendar">
							<template slot="BodyFormLoad">
								<div class="card-body">
									<div id="calendario" class="full-calendar">
										<FullCalendar class="full-calendar" ref="fullCalendar"
										:options="calendarOptions"
										/>
		
										<Modal :poBtnSave="poBtnSave" :Showbutton="false" :size="size" :Nombre="NameList">
											<template slot="Form">
												<Form :pOrigen="'activas'" :poBtnSave="poBtnSave" :pIdUsuario="IdUsuario"></Form>
											</template>
										</Modal>
									</div>
								</div>
							</template>
						</CLoader>
					</div>
				</div>

				<div class="col-md-12 col-lg-3 col-xl-3">
					<div class="card card-scroll">
						<CLoader :pConfigLoad="ConfigLoadSide">
							<template slot="BodyFormLoad">
								<div class="card-body">
									<div class="row">
										<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
											<h5 class="card-title">{{ FechaSeleccionada }}</h5>
										</div>
									</div>
									<table v-show="ListaAlarmas.length" class="table table-sm table-striped table-hover align_middle" style="border-collapse:separate;border-spacing:0 5px;">
										<thead>
											<tr>
												<th style="color:red;">Alarmas</th>
												<th>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in ListaAlarmas" :key="index">
												<td colspan="2" @click="Editar(item.IdSeguimientoCliente)" :style=" 'cursor:pointer; border-left:5px solid #FF0000'">
													<b>{{ $capitalize(item.Oportunidad) }}</b>
													<br>
													<b>Actividad: </b>{{$capitalize(item.Actividad)}}
													<br>
													<div v-if="item.IdClienteSucursal>0">
														<b>Cliente: </b>{{item.ClienteSucursal}}
													</div>
													<div v-else>
														<b>Actividad libre</b>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
									<table class="table table-sm table-striped table-hover align_middle" style="border-collapse:separate;border-spacing:0 5px;">
										<thead>
											<tr>
												<th>Actividades</th>
												<th>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in ListaTareas" :key="index">
												<td colspan="2" @click="Editar(item.IdSeguimientoCliente)" :style=" 'cursor:pointer; border-left: 5px solid ' + item.ColorTipo + ';' ">
													<b>{{ $capitalize(item.Oportunidad) }}</b>
													<br>
													<b>Actividad: </b>{{$capitalize(item.Actividad)}}
													<br>
													<div v-if="item.IdClienteSucursal>0">
														<b>Cliente: </b>{{item.ClienteSucursal}}
													</div>
													<div v-else>
														<b>Actividad libre</b>
													</div>
												</td>
												<td>
													<button v-show="item.Status" :id="'btn_' + item.IdSeguimientoCliente" type="button" @click="Eliminar(item.IdSeguimientoCliente,item.Fecha)" class="btn-icon-02">
														<i class="fas fa-trash-alt"></i>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</template>
						</CLoader>
					</div>
				</div>
			</div>
			<CLoader :pConfigLoad="ConfigLoadContadores">
				<template slot="BodyFormLoad">
					<div class="row mt-3">
						<!-- <div class="col-lg-2 ml-0 mr-0 pl-0 pr-0">
							<div class="card">
								<div class="card-body ml-0 mr-0 pl-0 pr-0">
									<h2 class="text-center">0</h2>
									<h4 class="text-center">Oportunidades Nuevas</h4>
								</div>
							</div>
						</div> -->
						<div class="col-lg-1">
							<div class="card">
								<div class="card-body ml-0 mr-0 pl-0 pr-0">
									<h1 class="text-center text-info" style="font-size:48px; margin-bottom: 0.1em;">{{ Contadores.ContadorOportunidadesNuevas }}</h1>
									<h4 class="text-center" style="color:#FF831B">Nuevas</h4>
								</div>
							</div>
						</div>
		
						<div class="col-lg-1">
							<div class="card">
								<div class="card-body ml-0 mr-0 pl-0 pr-0">
									<h1 class="text-center text-info" style="font-size:48px; margin-bottom: 0.1em;">{{ Contadores.ContadorOportunidadesActivas }}</h1>
									<h4 class="text-center" style="color:#FF831B">Activas</h4>
								</div>
							</div>
						</div>
		
						<div class="col-lg-1">
							<div class="card">
								<div class="card-body ml-0 mr-0 pl-0 pr-0">
									<h1 class="text-center text-info" style="font-size:48px; margin-bottom: 0.1em;">{{ Contadores.ContadorOportunidadesEnviadas }}</h1>
									<h4 class="text-center" style="color:#FF831B">Enviadas</h4>
								</div>
							</div>
						</div>
		
						<div class="col-lg-1">
							<div class="card">
								<div class="card-body ml-0 mr-0 pl-0 pr-0">
									<h1 class="text-center text-info" style="font-size:48px; margin-bottom: 0.1em;">{{ Contadores.ContadorOportunidadesPerdidas }}</h1>
									<h4 class="text-center" style="color:#FF831B">Perdidas</h4>
								</div>
							</div>
						</div>
		
						<div class="col-lg-1">
							<div class="card">
								<div class="card-body ml-0 mr-0 pl-0 pr-0">
									<h1 class="text-center text-info" style="font-size:48px; margin-bottom: 0.1em;">{{ Contadores.ContadorOportunidadesGanadas }}</h1>
									<h4 class="text-center" style="color:#FF831B">Ganadas</h4>
								</div>
							</div>
						</div>
						<!-- <div class="col-lg-1"></div> -->
		
						<div class="col-lg-2">
							<div class="card">
								<div class="card-body">
									<h1 class="text-center text-info" style="font-size:48px; margin-bottom: 0.1em;">${{ formateoMillon(Number(Contadores.MontoOportunidadesActivas)) }}</h1>
									<h4 class="text-center" style="color:#FF831B">Monto Activas</h4>
								</div>
							</div>
						</div>
		
						<div class="col-lg-2">
							<div class="card">
								<div class="card-body">
									<h1 class="text-center text-danger" style="font-size:48px; margin-bottom: 0.1em;">${{ formateoMillon(Number(Contadores.MontoOportunidadesPerdidas)) }}</h1>
									<h4 class="text-center" style="color:#FF831B">Monto Perdidas</h4>
								</div>
							</div>
						</div>
		
						<div class="col-lg-2">
							<div class="card">
								<div class="card-body">
									<h1 class="text-center text-success" style="font-size:48px; margin-bottom: 0.1em;">${{ formateoMillon(Number(Contadores.MontoOportunidadesGanadas)) }}</h1>
									<h4 class="text-center" style="color:#FF831B">Monto Ganadas</h4>
								</div>
							</div>
						</div>
					</div>
				</template>
			</CLoader>
			
			<br>
		</section>
		<Modal :NameModal="'ModalForm2'" :Showbutton="false" :size="'modal-lg'" :Nombre="'Ubicación'" >
			<template slot="Form">
				<Cmapa2 :Arreglo="markers" :rutatrab="rutatrab"></Cmapa2>
			</template>
		</Modal>
	</div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es.js";
import Form from "../oportunidades/calendario/form";
import Modal from "@/components/Cmodal.vue";
import Cmapa2 from "@/components/Cmapa2.vue";
import Menu from "../oportunidades/indexMenu.vue";

export default {
	props:['pIdNotificacion'],
	components: {
		FullCalendar, // make the <FullCalendar> tag available
		Form,
		Modal,
		Cmapa2,
		Menu
	},
	data: function() {
		return {
			calendarPlugins: [
				// plugins must be defined in the JS
				dayGridPlugin,
				timeGridPlugin,
				interactionPlugin // needed for dateClick
			],
			calendarWeekends: true,
			calendarEvents: [
				// initial event data
			],
			ConfigLoadContadores:{
				ShowLoader:true,
				ClassLoad:true
			},
			ConfigLoadCalendar:{
				ShowLoader:true,
				ClassLoad:true
			},
			ConfigLoadSide:{
				ShowLoader:true,
				ClassLoad:true
			},
			poBtnSave:{
				toast:0,
				disableBtn:false,
				nombreModal:'Seguimiento',
				EmitSeccion:'Seguimiento'
			},
			locale: esLocale,
			EsModal: true, //indica si es modal o no
			size: "modal-md",
			NameList: "Programar Actividad",
			EmitSeccion:'Seguimiento',
			ListaTareas: [],
			ListaAlarmas:[],
			FechaSeleccionada: "",
			markers: [],
			rutatrab: "",
			NombreSeccion: '',
			TxtDefault: 'Calendario de Actividades y Seguimiento de Oportunidades',
			ShowNext: false,
			RolUsuario:'',
			IdTrabajador:'',
			Contadores:{
				ContadorOportunidadesNuevas:0,
				ContadorOportunidadesActivas:0,
				ContadorOportunidadesEnviadas:0,
				ContadorOportunidadesGanadas:0,
				ContadorOportunidadesPerdidas:0,
				MontoOportunidadesActivas:0,
				MontoOportunidadesPerdidas:0,
				MontoOportunidadesGanadas:0
			},
			IdUsuario:0,
			ListActividades:[],
			calendarOptions:{
				locale:esLocale,
				contentHeight:500,
				// ref:"fullCalendar",
				initialView:'dayGridMonth',
				headerToolbar:{
					start: 'prev,next,today',
					center: 'title',
					end: 'dayGridMonth,timeGridWeek,timeGridDay'
				},
				plugins:[dayGridPlugin,timeGridPlugin,interactionPlugin],
				weekends:true,
				events:[],
				dayMaxEvents:4,
				dateClick:this.handleDateClick,
				eventClick:this.eventClick,
				datesSet: this.GetAnioCalendar,
				// class:'full-calendar'
			},
			Anio:0
		};
	},
	methods: {
		Nuevo() {
			this.bus.$emit("NewModal_"+this.EmitSeccion, true);
		},
		get_listcalendar() {
			this.ConfigLoadCalendar.ShowLoader=true;
			let params={};
			if (this.RolUsuario!="Admin") {
				params.IdTrabajador=this.IdTrabajador;
			}
			this.$http.get("crmseguimiento/list",{params:params}).then(res => {
				this.ListActividades=[];
				this.calendarOptions.events = [];

				const actividades = res.data.data.seguimiento;
				this.ListActividades=res.data.data.seguimiento;

				actividades.forEach((item, index) => {
					this.calendarOptions.events.push({
						start:item.Fecha +' 09:00:00',
						end:item.Fecha + ' 16:00:00',
						title: this.$limitCharacters(item.Actividad,7), //+' \n'+' Cliente: '+ item.ClienteSucursal+' \n'+' Inicio: '+ item.HoraInicio.substring(0,5) ,
						allDay: true,
						id: item.IdSeguimientoCliente,
						color: item.ColorTipo,
						background: item.ColorTipo,
						textColor: '#FFFFFF'
					});
				});
				this.ConfigLoadCalendar.ShowLoader=false;
			});
		},
		AgregarEventoCalendario(){

		},
		get_listtareas(Fecha) {
			this.ConfigLoadSide.ShowLoader=true;
			let params={
					Fecha:Fecha
				}
			if(this.RolUsuario!="Admin"){
				params.IdTrabajador=this.IdTrabajador;
			}
			this.$http.get("crmseguimiento/list", {
					params: params
				})
				.then(res => {
					this.ListaTareas = res.data.data.seguimiento;
					this.ListaAlarmas = res.data.data.alarmas;
					for (let index = 0; index < this.ListaTareas.length; index++) {
						if (this.ListaTareas[index].Recordatorio!="" && this.ListaTareas[index].Recordatorio=="s") {
							this.ListaAlarmas.push(this.ListaTareas[index]);
						}

					}
					//añade zeros (para comparar la hora)
					function addZero(i) {
						if (i < 10) {
							i = "0" + i;
						}
						return i;
					}
					//obtiene fecha, hora, minuto y segundo actual
					const current = new Date();
					let day = String(current.getDate()).padStart(2, "0"); //añade zeros (para comparar la fecha)
					let month = String(current.getMonth() + 1).padStart(2, "0");
					let year = current.getFullYear();
					let hour = addZero(current.getHours());
					let min = addZero(current.getMinutes());
					let sec = addZero(current.getSeconds());
					let dateActual = year + "-" + month + "-" + day;
					let timeActual = hour + ":" + min + ":" + sec;
					//añade Status a cada objeto y valida si la hora y fecha han pasado para ocultar el botón de eliminar (v-show)
					this.ListaTareas.forEach((item, index) => {
						var bandera = true;
						if (item.Fecha <= dateActual && item.HoraFin <= timeActual) {
							bandera = false;
						}
						item.Status = bandera;
					});
					this.ConfigLoadSide.ShowLoader=false;
				});
		},
		Eliminar(Id,fechastr) {
			this.$swal({
				title: "Esta seguro que desea eliminar este dato?",
				text: "No se podra revertir esta acción",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "No, mantener",
				showCloseButton: true,
				showLoaderOnConfirm: true
			}).then(result => {
				if (result.value) {
					this.$toast.success("Información eliminada");

					this.$http.delete("crmseguimiento/" + Id).then(res => {
						var fecha={
							dateStr:fechastr
						}
						this.bus.$emit("ListAgendaS",fecha);
					});
				}
			});
		},
		toggleWeekends() {
			this.calendarWeekends = !this.calendarWeekends; // update a property
		},
		gotoPast() {
			let calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."
			calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
		},
		// ULTIMO DIA DEL MES
		UltimoDiaMes(anio,mes){
			return new Date(anio,mes+1,0);
		},
		handleDateClick(arg) {
			// console.log(arg);
			let data = { Fecha: arg.dateStr };
			this.FechaSeleccionada = data.Fecha;
			localStorage.setItem("fechacalendario", data.Fecha);//

			let dias = [
				"Lunes",
				"Martes",
				"Miércoles",
				"Jueves",
				"Viernes",
				"Sábado",
				"Domingo"
			];
			let meses = [
				"Enero",
				"Febrero",
				"Marzo",
				"Abril",
				"Mayo",
				"Junio",
				"Julio",
				"Agosto",
				"Septiembre",
				"Octubre",
				"Noviembre",
				"Diciembre"
			];

			let date = new Date(data.Fecha);
			console.log(data.Fecha);
			// console.log(date);
			// OBTENER EL ULTIMO DIA DEL MES TOMANDO EN CUENTA LA FECHA DEFINIDA:
			let fechaultimo=this.UltimoDiaMes(date.getFullYear(),date.getMonth());
			// console.log(fechaultimo);
			// VALIDAR SI ES EL ULTIMO DIA DEL MES:
			var fechaNum;
			var mes_name;
			if (date.toDateString()==fechaultimo.toDateString()) {
				// console.log("toma");
				fechaNum = 1;
				// Si estamos en diciembre:
				// console.log(date.getMonth()+1);
				if (date.getMonth()+1==12) {
					mes_name=0;
				}
				else{
					mes_name = date.getMonth()+1;
				}
			}
			else{
				fechaNum = date.getDate() + 1;
				mes_name = date.getMonth();
			}

			this.FechaSeleccionada =
				dias[date.getDay()] +
				" " +
				fechaNum +
				" de " +
				meses[mes_name] +
				" de " +
				date.getFullYear();

			this.get_listtareas(data.Fecha);
		},
		eventClick: function(calEvent, jsEvent, view) {
			this.Editar(calEvent.event.id);
		},
		Editar(Id) {
			let Index=this.ListActividades.findIndex((element)=>element.IdSeguimientoCliente==Id);
			if (Index!==-1) {
				this.NameList=this.ListActividades[Index].Oportunidad;
			}
			else{
				this.NameList='Programar Actividad';
			}
			this.bus.$emit("NewModal_"+this.poBtnSave.EmitSeccion, null, Id);
			$("#ModalForm").modal("show");
		},
		Contactos() {
			this.$router.push({ name: "crmcontactos", params: {} });
		},
		Oportunidad() {
			this.$router.push({ name: "crmoportunidad", params: {} });
		},
		Procesos() {
			this.$router.push({ name: "crmtiposprocesos", params: {} });
		},
		vendedores() {
			this.$router.push({ name: "crmvendedores", params: {} });
		},
		pipedrive() {
			this.$router.push({ name: "crmpipedrive", params: {} });
		},
		forecast() {
			this.$router.push({ name: "crmforecast", params: {} });
		},
		Open_Ubicacion() {
			this.bus.$emit("OpenModal");
		},
		async getUbicaciones() {
			await this.$http
				.get("crm/ubicaciones/get", {
					params: {}
				})
				.then(res => {
					this.rutatrab = res.data.data.ruta;
					this.markers = [];
					res.data.data.ubicaciones.forEach(element => {
						this.markers.push({
							position: {
								lat: parseFloat(element.Latitud),
								lng: parseFloat(element.Longitud)
							},
							datos: {
								Tecnico: element.Nombre,
								// Cliente: element.ClienteSucursal,
								Actividad: element.Telefono,
								Direccion: element.Direccion,
								FechaI: "",
								FechaF: "",
								// HoraI: element.HoraInicio,
								// HoraF: element.HoraFin,
								// Estatus: "",
								// Foto2: RutaFoto,
								Foto2:element.LogoCliente
								// Tipo: "Vendedor"
							}
						});
					});
				});
		},
		ListContadores(){
			this.ConfigLoadContadores.ShowLoader=true;
			this.$http.get("oportunidades/contadores/get",
			{
				params:{
					Anio:this.Anio
				}
			}
			).then((res)=>{
				this.Contadores.ContadorOportunidadesNuevas=res.data.data.OportunidadesNuevas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
				this.Contadores.ContadorOportunidadesActivas=res.data.data.OportunidadesActivas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
				this.Contadores.ContadorOportunidadesEnviadas=res.data.data.OportunidadesEnviadas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
				this.Contadores.ContadorOportunidadesGanadas=res.data.data.OportunidadesGanadas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
				this.Contadores.ContadorOportunidadesPerdidas=res.data.data.OportunidadesPerdidas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
				if (res.data.data.MontoOportunidadesActivas==null ? this.Contadores.MontoOportunidadesActivas=0 : this.Contadores.MontoOportunidadesActivas=res.data.data.MontoOportunidadesActivas);
				if (res.data.data.MontoOportunidadesPerdidas==null ? this.Contadores.MontoOportunidadesPerdidas=0 : this.Contadores.MontoOportunidadesPerdidas=res.data.data.MontoOportunidadesPerdidas);
				if (res.data.data.MontoOportunidadesGanadas==null ? this.Contadores.MontoOportunidadesGanadas=0 : this.Contadores.MontoOportunidadesGanadas=res.data.data.MontoOportunidadesGanadas);
				this.ConfigLoadContadores.ShowLoader=false;
			})
		},
		formateoMillon(numero){
            let fixed = 0;
			let e=0;
			if (numero === null) {
				return null;
			} // terminate early
			if (numero === 0) {
				return "0";
			} // terminate early
			fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
			var b = numero.toPrecision(2).split("e"), // get power
				k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 6), // floor at decimals, ceiling at trillions
				c =
					k < 1
						? numero.toFixed(0 + fixed)
						: (numero / Math.pow(10, k * 6)).toFixed(1 + fixed), // divide by power
				d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
				if (k==0) {
					// e=new Intl.NumberFormat('en-US',{style:'currency',currency:'USD',roundingPriority:'morePrecision'}).format(d);
					e=d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				}
				else{
					e = d + ["", " M", " B", " T"][k]; // append power
				}
			return e;
        },
		GetAnioCalendar(){
			// this.Anio=new Date(Obj.startStr).getFullYear();
			// console.log(this.$refs.fullCalendar.getApi().getCurrentData());
			let Fecha=new Date(this.$refs.fullCalendar.getApi().getCurrentData().currentDate);
			let Anio=new Date(Fecha.setDate(Fecha.getDate()+1)).getFullYear();
			if (this.Anio!=Anio) {
				this.Anio=Anio;
				this.ListContadores();
			}
		}

	},
	created() {
		// window.calendario=this;
		this.Anio=new Date().getFullYear();
		this.RolUsuario=JSON.parse(sessionStorage.getItem("user")).Perfil;
		this.IdUsuario=JSON.parse(sessionStorage.getItem("user")).IdUsuario;
		if (this.RolUsuario!="Admin") {
			this.IdTrabajador=JSON.parse(sessionStorage.getItem("user")).IdUsuario;
		}
		var f = new Date();
		var FF = f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate();

		let dias = [
			"Lunes",
			"Martes",
			"Miércoles",
			"Jueves",
			"Viernes",
			"Sábado",
			"Domingo"
		];
		let meses = [
			"Enero",
			"Febrero",
			"Marzo",
			"Abril",
			"Mayo",
			"Junio",
			"Julio",
			"Agosto",
			"Septiembre",
			"Octubre",
			"Noviembre",
			"Diciembre"
		];

		let date = new Date(FF);

		var fechaNum = date.getDate();
		var mes_name = date.getMonth();

		if (dias[fechaNum] != undefined) {
			var date2 = new Date(date);
		} else {
			var date2 = new Date();
		}
		let options = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric"
		};

		var FF = f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate();
		let Dia = f.getDate();
		let Mes = f.getMonth() + 1;
		let Anio = f.getFullYear();

		let Dia2 = Dia;

		if (Dia < 10) {
			Dia2 = "0" + Dia;
		}

		let Mes2 = Mes;
		if (Mes < 10) {
			Mes2 = "0" + Mes;
		}

		let FechaN = Anio + "-" + Mes2 + "-" + Dia2;

		let NombreFecha = date2.toLocaleDateString("es-MX", options);
		let fechaNum2=f.getDate();
		localStorage.setItem("fechacalendario", FechaN);

		this.FechaSeleccionada =
				dias[f.getDay()-1] +
				" " +
				fechaNum2 +
				" de " +
				meses[mes_name] +
				" de " +
				date.getFullYear();

		this.get_listcalendar();
		this.get_listtareas(FF);

		this.bus.$off("ListAgendaS");
		this.bus.$on("ListAgendaS", (fecha) => {
			this.get_listcalendar();
			this.handleDateClick(fecha);
		});

		this.bus.$off("ListarUbicacion");
		this.bus.$on("ListarUbicacion", () => {
			this.getUbicaciones();
		});
		this.ListContadores();
	},
	mounted:function(){
		// console.log("a");
		if (this.pIdNotificacion!=undefined) {
			setTimeout(() => {
				this.Editar(this.pIdNotificacion);
			}, 1500);
		}
	}
};
</script>

<style lang="scss">
@import "~@fullcalendar/common/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

.fc-daygrid-more-link {
	font-size: 14px;
	font-weight: bold;
}
.fc-daygrid-day-bottom {
	text-align: center;
}
.fc-day-today{
	background-color: #FFF !important;
}
</style>
