<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="row">
                    <div class="col-lg-4">
                        <label for="">Nombre *</label>
                        <input type="text" v-model="Refaccion.Descripcion" placeholder="Nombre" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Descripcion" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-4">
                        <label for="">Marca *</label>
                        <input type="text" v-model="Refaccion.Marca" placeholder="Marca" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Marca" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-4">
                        <label for="">Modelo *</label>
                        <input type="text" v-model="Refaccion.Modelo" placeholder="Modelo" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Modelo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <label for="">Cantidad *</label>
                        <input type="text" v-model="Refaccion.Cantidad" placeholder="Cantidad" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Cantidad" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-3">
                        <label for="">N° Parte *</label>
                        <input type="text" v-model="Refaccion.NumParte" placeholder="N° Parte" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.NumParte" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-3">
                        <label for="">Tiempo de Reemplazo *</label>
                        <div class="input-group">
                            <input type="text" v-model="Refaccion.Periodo" placeholder="Periodo" class="form-control">
                            <select name="" id="" class="form-control" v-model="Refaccion.TipoPeriodo">
                                <option value="">Seleccione una Opción</option>
                                <option value="1">Meses</option>
                                <option value="2">Años</option>
                            </select>
                        </div>
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Periodo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-3">
                        <label for="">Tipo *</label>
                        <!-- <input type="text" v-model="Refaccion.Periodo" placeholder="Periodo" class="form-control"> -->
                        <select v-model="Refaccion.Tipo" class="form-control">
                            <option value="">Seleccione un Tipo</option>
                            <option value="1">Genérico</option>
                            <option value="2">OEM</option>
                        </select>
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Tipo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label for="">Valor Aproximado</label>
                        <vue-numeric
                            class="form-control "
                            currency="$"
                            separator=","
                            placeholder="$0.00"
                            :precision="2"
                            v-model="Refaccion.Valor"
                        ></vue-numeric>
                    </div>
                    
                    <div class="col-lg-4">
                        <label for="">Fecha de Actualización de Valor *</label>
                        <v-date-picker v-model="Refaccion.FechaActValor" :mode="typeCalendar"
                            :popover="{
                                placement: 'bottom',
                                visibility: 'click'
                            }"
                            :input-props="{
                                class: 'form-control cal-black',
                                style: 'cursor:pointer;'
                            }"
                            :locale="'es-MX'"
                        />
                    </div>
        
                    <div class="col-lg-4">
                        <label for="">Archivo</label>
                        <div class="custom-file-input-image">
                            <input @change="uploadImage()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                            <input type="text" v-model="Archivo" class="form-control">
                            <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                        </div>
                    </div>
                </div>
            </template>
        </CLoader>
    </div>
</template>
<script>
import moment from "moment"
import Cbtnsave from '@/components/Cbtnsave.vue';
import Cvalidation from '@/components/Cvalidation.vue';

export default{
    name:"Form",
    props:[
        "poBtnSave",
        "IdActivo"
    ],
    data(){
        return {
            Modal:true,
            FormName:'Refacciones',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            },
            Refaccion:{
                IdRefaccion:'',
                IdActivo:'',
                Descripcion:'',
                Marca:'',
                Modelo:'',
                Cantidad:'',
                NumParte:'',
                Periodo:'',
                TipoPeriodo:'',
                Tipo:'',
                Valor:0,
                FechaActValor:'',
                Archivo:''
            },
            Archivo:'Elegir Archivo (3MB)',
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            let Fecha='';
            if (this.Refaccion.FechaActValor!='' && this.Refaccion.FechaActValor!=undefined) {
                let day=this.Refaccion.FechaActValor.getDate();
                let month=this.Refaccion.FechaActValor.getMonth()+1;
                let year=this.Refaccion.FechaActValor.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
            let file = this.$refs.file.files[0];
            let formData=new FormData();
            formData.set('IdRefaccion',this.Refaccion.IdRefaccion);
            formData.set('IdActivo',this.IdActivo);
            formData.set('Descripcion',this.Refaccion.Descripcion);
            formData.set('Marca',this.Refaccion.Marca);
            formData.set('Modelo',this.Refaccion.Modelo);
            formData.set('Cantidad',this.Refaccion.Cantidad);
            formData.set('NumParte',this.Refaccion.NumParte);
            formData.set('Periodo',this.Refaccion.Periodo);
            formData.set('TipoPeriodo',this.Refaccion.TipoPeriodo);
            formData.set('Valor',this.Refaccion.Valor);
            formData.set('FechaActValor',Fecha);
            formData.set('Tipo',this.Refaccion.Tipo);
            formData.set('Archivo',this.Refaccion.Archivo);
            formData.append('ArchivoFile',file);
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('refacciones/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $('#'+this.EmitSeccion).modal('hide');
                this.bus.$emit('List_'+this.EmitSeccion);
                // this.bus.$emit('Listar');
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2; 
            });
        },
        Limpiar(){
            this.errorvalidacion=[];
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';
            this.Refaccion={
                IdRefaccion:'',
                IdActivo:'',
                Descripcion:'',
                Marca:'',
                Modelo:'',
                Cantidad:'',
                NumParte:'',
                Periodo:'',
                TipoPeriodo:'',
                Tipo:'',
                Valor:0,
                Archivo:'Elegir Archivo (3MB)',
                FechaActValor:new Date()
            };
            // if (localStorage.getItem("fechacalendario")) {
			// 	let NuevaFecha = localStorage.getItem("fechacalendario");
			// 	let formatedDate = NuevaFecha.replace(/-/g, "\/");
			// 	this.Refaccion.FechaActValor = new Date(formatedDate);
			// }
            this.Archivo='Elegir Archivo (3MB)';
        },
        Recuperar(){
          this.$http.get('refacciones/recovery',{
            params:{
                    IdRefaccion:this.Refaccion.IdRefaccion
                }
          }).then((res)=>{
                const objres=res.data.data.refaccion;
                this.Refaccion.IdRefaccion=objres.IdRefaccion;
                this.Refaccion.IdActivo=objres.IdActivo;
                this.Refaccion.Descripcion=objres.Descripcion;
                this.Refaccion.Marca=objres.Marca;
                this.Refaccion.Modelo=objres.Modelo;
                this.Refaccion.Cantidad=objres.Cantidad;
                this.Refaccion.NumParte=objres.NumParte;
                this.Refaccion.Periodo=objres.Periodo;
                this.Refaccion.TipoPeriodo=objres.TipoPeriodo;
                this.Refaccion.Tipo=objres.Tipo;
                this.Refaccion.Valor=objres.Valor;
                let formatedDate = objres.FechaActValor.replace(/-/g, "\/");
                this.Refaccion.FechaActValor=new Date(formatedDate);
                if (objres.Archivo=='') {
                    this.Refaccion.Archivo='Elegir Archivo (3MB)';
                    this.Archivo='Elegir Archivo (3MB)';
                }
                else{
                    this.Refaccion.Archivo=objres.Archivo;
                    this.Archivo=objres.Archivo;
                }
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            })
        },
        uploadImage(){
            const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 3) {
                this.$toast.info('Solo se puede subir archivos menores a 3 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extenciones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.Archivo='Elegir archivo (5MB)';
                return false;
            }

            this.Archivo=image.name;
        },
        MinimoFecha(){
            let fecha='';
			if(this.Refaccion.FechaActValor!=""){
				var FechaAnterior=new Date(this.Refaccion.FechaActValor);
				var Actual=new Date();
				if (FechaAnterior.getTime()>=Actual.getTime()) {
					fecha=new Date();
				}
				else{
					fecha=moment(this.Refaccion.FechaActValor).add(1,'day').format('YYYY-MM-DD');
					fecha=new Date(fecha);
				}
				
			}
			else{
				fecha=new Date();
			}
			return fecha;
        }
    },
    created(){
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id)=>{
            this.ConfigLoad.ShowLoader=true;
            // this.Documento.IdActivo=this.IdActivo
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (Id > 0) {
				this.Refaccion.IdRefaccion = Id;
				this.Recuperar();
			}
            else{
                this.ConfigLoad.ShowLoader=false;
            }
        });
    },
    computed:{
        typeCalendar:function(){
            return 'single';
        }
    }
}
</script>