<template>
    <div>
        <!-- <div>
            {{ Tempo}}
        </div> -->
        <CHeader :pHeaderProps=HeaderProps>
            <template slot="Contenido">
                <!-- ESTA ES LA PARTE DE LA WEBCAM -->
                 <div v-show="ShowCamara">
                    <div class="photo-servicio-container">
                        <div style="width:100%;position:fixed;height:100vh">
                            <div class="pb-0 mb-0 camera-container" style="height:80vh;">
                                <video class="video-camera" id="videoCam" ref="videoCam" muted playsinline></video>
                            </div>
                            <div class="row mt-0 pt-0">
                                <div style="height:20vh;background:#000" class="col-12 d-flex justify-content-center">
                                    <button v-if="!ShowConfirmacionFoto" @click="TomarFoto" id="tomar-foto" ref="tomar-foto" class="mt-3"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="ShowConfirmacionFoto" class="position-absolute confirmacion-photo">
                        <canvas @canplay="AjustarCanvas" id="canvas" ref="canvas" style="display:none;"></canvas>
                        <div class="btn-confirmar">
                            <button class="btn btn-01" @click="ConfirmarFoto"><i class="fa fa-check fa-3x"></i></button>
                        </div>
                        <div class="btn-rechazar">
                            <button class="btn btn-01" @click="VolverATomarFoto"><i class="fa fa-times-circle fa-3x"></i></button>
                        </div>
                    </div>
                 </div>
                 <!-- AQUI FINALIZA LA PARTE DE LA WEBCAM -->
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <div v-show="ShowCheckIn" class="row mt-3 align-items-center" style="height:80vh;">
                            <div class="col-lg-12">
                                <h2 class="text-center mt-4">Hora:</h2>
                                <h2 class="text-center mt-2 mb-4" id="hora-check-in"></h2>
                                <button @click="SetTipoCheck('checkin')" class="m-auto mt-4 btn btn-success btn-block boton-check-in-circle">Check-In</button>
                                <input @change="TomarFoto" id="InputCamara" style="display:none;" type='file' accept='image/*' ref="foto" capture='user'/>
                            </div>
                        </div>
                        <div v-show="ShowBotones" class="row mt-3" style="height:80vh;">
                            <!-- <div class="col-lg-12" v-if="isButtonCheckInDisabled">
                                <h5>Tiempo Para Realizar Otra Acción</h5>
                                <h5 id="temporizador"></h5>
                            </div> -->
                            <div class="col-lg-12">
                                <button :disabled="(HoraComidaTerminada && HoraComidaUsada) || CheckInExtra" data-toggle="modal" :data-target="isButtonCheckInDisabled ? '' : '#ModalForm'"  data-backdrop="static" data-keyboard="false" type="button" @click="SetTipoCheck('comida')" class="btn btn-pink btn-block boton-check-in"><i class="fa fa-utensil-fork mr-2"></i> Comida</button>
                            </div>
                            <div class="col-lg-12">
                                <button :disabled="!HoraComidaTerminada || ContadorEvento==4 || CheckInExtra" data-toggle="modal" :data-target="isButtonCheckInDisabled ? '' : '#ModalForm'"  data-backdrop="static" data-keyboard="false" type="button" @click="SetTipoCheck('evento')" class="btn btn-primary btn-block boton-check-in"><i class="fa fa-bell mr-2"></i>Evento</button>
                            </div>
                            <div class="col-lg-12">
                                <button :disabled="!HoraComidaTerminada" @click="SetTipoCheck('checkin')" class="btn btn-01 btn-block boton-check-in"><i class="fa fa-sign-out-alt mr-2"></i>Check-Out</button>
                            </div>
                        </div>
                    </template>
                </CLoader>
                <div v-show="ShowComentario" class="row mt-3">
                    <div class="col-lg-12">
                        <div class="d-flex flex-column">
                            <div class="cropp">
                                <img src="" alt="" id="photo" ref="photo">
                            </div>
                        </div>
                    </div>
                    <div v-if="HoraExtra" class="col-12 col-sm-12 mt-4">
                        <div class="form-check form-check-inline">
                            <div class="checkbox">
                                <label>
                                    <span class="mr-1" style="font-size:15px;">¿Fueron Autorizadas Tus Horas Extra?</span>
                                    <input
                                        type="checkbox"
                                        v-model="HoraExtraValor"
                                        name="optionsCheckboxes"
                                    /><span class="checkbox-material-green"
                                        ><span class="check"></span
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 mt-1">
                        <label for="">Comentario</label><small> (max 100 caracteres)</small>
                        <label for="" v-if="HoraExtra">Especificar quién autorizó las horas extra</label>
                        <textarea class="form-control" v-model="Comentario" id="" cols="30" rows="10" placeholder="Escriba aquí sus comentarios..." maxlength="100"></textarea>
                    </div>
                    <div class="col-12 col-sm-12 mb-3 mt-3">
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                <button @click="Cancelar" :disabled="DisableBtn" class="btn btn-01 btn-block btn-grande" type="button">Cancelar</button>
                            </div>
                            <div class="col-6 col-sm-6">
                                <button @click="Guardar" :disabled="DisableBtn" class="btn btn-01 btn-block btn-grande" type="button">
                                    <i v-show="DisableBtn" class="fa fa-spinner fa-pulse fa-fw"></i> Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CHeader>
        <Modal :pModalProps="ModalProps">
            <template slot="Form">
                <Form :poBtnSave="ModalProps"></Form>
            </template>
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import Modal from '@/views/webapp/components/ModalWebApp.vue';
import CLoaderVue from '../../../components/CLoader.vue';
import Form from "@/views/webapp/checkin/CheckInForm.vue";
export default {
    components:{
        CHeader,
        Modal,
        Form,
        CLoaderVue
    },
    data() {
        return {
            HeaderProps:{
                ShowReturn:true
            },
            ModalProps:{
                Tamanio:'modal-md',
                Titulo: "Registro de ",
                NombreModal: "ModalForm",
                EmitSeccion:"CheckIn",
                ShowBtnCancel:false,
                DisableBtn:false,
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            localstream:null,
            DisableBtn:false,
            ShowCheckIn:true,
            ShowBotones:false,
            ShowComentario:false,
            ShowCamara:false,
            ShowConfirmacionFoto:false,
            CheckOut:false,
            HoraExtra:false,
            IdTrabajador:'',
            TipoCheck:'',
            Latitud:'',
            Longitud:'',
            Comentario:'',
            Foto:'',
            HoraEntrada:'',
            HoraSalida:'',
            Tolerancia:'',
            Estatus:'',
            ListaChecks:[],
            IdCheck:0,
            ContadorEvento:1,
            HoraExtraValor:false,
            CantidadHoraExtra:0,
            HoraComidaUsada:false,
            HoraComidaTerminada:true,
            AutHoraExtra:false,
            CheckInExtra:false,
            Servicios:[]
        }
    },
    methods: {
        async Buscar(){
            this.ContadorEvento=1;
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            await this.$http.get('webapp/consultacheckin',{
                params:{
                    IdTrabajador:this.IdTrabajador
                }
            }).then((res)=>{
                this.ListaChecks=res.data.data;
                if (this.ListaChecks.length==0) {
                    this.ShowCheckIn=true;
                }
                else{
                    // console.log("emergencia1".match(/[a-z]+/));
                    let Index=this.ListaChecks.findIndex((element)=>element.TipoCheck=="checkin");
                    this.IdCheck=this.ListaChecks[Index].IdCheck;
                    this.HoraExtraValor=this.ListaChecks[Index].HoraExtra=="0" ? false : true;
                    this.CantidadHoraExtra=this.ListaChecks[Index].CantidadHoraExtra;
                    this.CheckOut=true;
                    this.ShowCheckIn=false;
                    this.ShowBotones=true;
                    // CONTAR EVENTOS
                    this.ListaChecks.forEach(element => {
                        if(/\d/.test(element.TipoCheck) && element.FechaCheckOut!=null){
                            this.ContadorEvento++;
                        }
                    });
                    //SABER SI SE USO LA HORA DE COMIDA
                    let IndexComida=this.ListaChecks.findIndex((element)=>element.TipoCheck=="comida");
                    if (IndexComida!=-1) {
                        this.HoraComidaUsada=true;
                        this.HoraComidaTerminada=this.ListaChecks[IndexComida].FechaCheckOut==null ? false : true;
                    }
                    // PARA EL SEGUNDO CHECKIN
                    let FiltroCheckIn=this.ListaChecks.filter((element)=>element.TipoCheck=='checkin');
                    // console.log(FiltroCheckIn.length);
                    if (FiltroCheckIn.length==1) {
                        if (this.ListaChecks[Index].FechaCheckOut!=null) {
                            this.ShowCheckIn=true;
                            this.ShowBotones=false;
                            this.CheckInExtra=true;
                            this.CheckOut=false;
                            this.IdCheck=0;
                        }
                        else{
                            // let NuevoIndex=this.ListaChecks.findIndex((element)=>element.FechaCheckOut==null && element.TipoCheck=='checkin');
                            // if (NuevoIndex!=-1) {
                            //     this.CheckInExtra=true;
                            //     this.IdCheck=this.ListaChecks[NuevoIndex].IdCheck;
                            //     this.CheckOut=true;
                            // }
                            // else{
                            //     this.CheckOut=false;
                            // }
                        }
                    }
                    else{
                        let NuevoIndex=this.ListaChecks.findIndex((element)=>element.FechaCheckOut==null && element.TipoCheck=='checkin');
                        if (NuevoIndex!=-1) {
                            this.CheckInExtra=true;
                            this.IdCheck=this.ListaChecks[NuevoIndex].IdCheck;
                            this.CheckOut=true;
                        }
                        else{
                            this.CheckOut=false;
                        }
                    }
                }
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            });
        },
        async ListServicios(){
            let Fecha=new Date();
            let FechaFiltro=Fecha.getFullYear()+'-'+(Fecha.getMonth()+1)+'-'+Fecha.getDate();
            await this.$http.get('webapp/servicios',{
                    params:{
                        FechaI:FechaFiltro,
                        Tipo:'',
                        IdTrabajador:this.IdTrabajador,
                    }
                }).then((res)=>{
                    this.Servicios=res.data.Lista;
                    this.Buscar();
                })
        },
        Guardar(){
            this.DisableBtn=true;
            if (!this.CheckInExtra) {
                let FechaActual=new Date();
                // FECHA FALSEADA PARA PRUEBAS
                // FechaActual.setDate(FechaActual.getDate()+1);
                // FechaActual.setHours(4,30,0,0);
                // FECHA REAL
                FechaActual.setHours(FechaActual.getHours(),FechaActual.getMinutes(),0,0);
                let IntervaloMayor=0;
                let ActualMilisegundos=FechaActual.getTime();
                let HoraEntradaMilisegundo=0;
                let SalidaMilisegundos=0;
                if (this.CheckOut) {
                    let FechaSalida=new Date();
                    let PartesHoraSalida=this.HoraSalida.split(':');
                    FechaSalida.setHours(Number(PartesHoraSalida[0]),Number(PartesHoraSalida[1]),0,0);
                    if (Number(this.HoraEntrada.split(':')[0])>Number(PartesHoraSalida[0])) {
                        FechaSalida.setDate(FechaSalida.getDate()+1);
                    }
                    SalidaMilisegundos=FechaSalida.getTime();
                }
                else{
                    let FechaEntrada=new Date();
                    let PartesHoraEntrada=this.HoraEntrada.split(':');
                    FechaEntrada.setHours(Number(PartesHoraEntrada[0]),Number(PartesHoraEntrada[1]),0,0);
                    HoraEntradaMilisegundo=FechaEntrada.getTime();
                    let ToleranciaMilisegundo=parseInt(this.Tolerancia)*60*1000;
                    IntervaloMayor=HoraEntradaMilisegundo+ToleranciaMilisegundo;
                    if (ActualMilisegundos>=IntervaloMayor) {
                        this.Estatus='Falta';
                    }
                    else if(ActualMilisegundos>HoraEntradaMilisegundo && ActualMilisegundos<IntervaloMayor){
                        this.Estatus='Retardo';
                    }
                    else{
                        this.Estatus='Puntual';
                    }
                }
                //SACAR LA CANTIDAD DE HORAS EXTRA, COMO NOTA NO OLVIDAR EVALUAR ESTO CuANDO EL TRABAJADOR TENGA AUTORIZADAS ESAS HORAS
                this.EvaluarHoraExtra();
                if (this.HoraExtraValor) {
                    if (this.CheckOut) {
                        // let SalidaMilisegundos=FechaSalida.getTime();
                        let Diferencia=ActualMilisegundos-SalidaMilisegundos;
                        this.CantidadHoraExtra=parseInt(this.CantidadHoraExtra)+Math.floor(Diferencia/3600000);
                    }
                    else{
                        let Diferencia=HoraEntradaMilisegundo-ActualMilisegundos;
                        this.CantidadHoraExtra=parseInt(this.CantidadHoraExtra)+Math.floor(Diferencia/3600000);
                    }
                    this.Estatus='Horas Extra';
                }
            }
            else{
                this.Estatus='Horas Extra';
                this.HoraExtraValor=true;
                this.CantidadHoraExtra=0;
                let IndexCheck=this.ListaChecks.findIndex((element)=>element.TipoCheck=='checkin' && element.FechaCheckOut==null);
                if (IndexCheck!=-1) {
                    let FechaActual=new Date();
                    // FALSEADO DE FECHA ACTUAL
                    // FechaActual.setHours(15,50,0,0);
                    //FECHA REAL
                    FechaActual.setHours(FechaActual.getHours(),FechaActual.getMinutes(),0,0);
                    let ActualMilisegundos=FechaActual.getTime();
                    let PartesFechaEntrada=this.ListaChecks[IndexCheck].FechaReg.split(/\s/);
                    let PartesHoraEntrada=PartesFechaEntrada[1].split(':');
                    let formatedDate = PartesFechaEntrada[0].replace(/-/g, "\/");
                    let FechaEntrada=new Date(formatedDate);
                    FechaEntrada.setHours(Number(PartesHoraEntrada[0]),Number(PartesHoraEntrada[1]),0,0);
                    let HoraEntradaMilisegundo=FechaEntrada.getTime();
                    let Diferencia=ActualMilisegundos-HoraEntradaMilisegundo;
                    let CantidadHoraExtra=Math.floor(Diferencia/3600000);
                    this.CantidadHoraExtra=CantidadHoraExtra>0 ? CantidadHoraExtra : 1;
                }
            }

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=>{
                    this.Latitud=position.coords.latitude;
                    this.Longitud=position.coords.longitude;
                    let formData=new FormData();
                    formData.set('IdCheck',this.IdCheck);
                    formData.set('Estatus',this.Estatus);
                    formData.set('IdTrabajador',this.IdTrabajador);
                    formData.set('TipoCheck',this.TipoCheck);
                    formData.set('Comentario',this.Comentario);
                    formData.set('Latitud',this.Latitud);
                    formData.set('Longitud',this.Longitud);
                    formData.set('HoraExtra',this.HoraExtraValor ? 1 : 0);
                    formData.set('CantidadHoraExtra',this.CantidadHoraExtra);
                    formData.append('Imagen',this.Foto.split("base64,")[1]);
                    this.$http.post('webapp/checkinsave',formData,{
                        timeout:10000
                    }
                    ).then((res)=>{
                        this.ShowComentario=false;
                        this.ShowCheckIn=true;
                        this.$toast.success(res.data.message);
                        this.$store.dispatch('ActualizarCheckIn');
                        this.DisableBtn=false;
                        // navigator.geolocation.clearWatch(WatchId);
                        this.Estatus='';
                        if (!this.CheckOut) {
                            if (!this.CheckInExtra) {
                                this.$store.state.CheckInEstatus='Checkin';
                            }
                            this.bus.$emit('EventoTimerCheckOut');
                            this.$router.push({ name: "HomeWebApp", params: {} });
                        }
                        else {
                            this.$store.state.CheckInEstatus='';
                            this.$store.dispatch("logout");
			                this.$router.push({ name: "Login" });
                        }
                    }).catch((err)=>{
                        console.log(err.code);
                        if (err.code=='ECONNABORTED') {
                            this.$toast.warning('La Solicitud Tardó en Responder, Por Favor Inténtelo de Nuevo');
                            this.DisableBtn=false;
                        }
                        this.DisableBtn=false;
                        this.$toast.warning(err.response.data.message);
                        this.Estatus='';
                    })
                },()=>{},{enableHighAccuracy: true,maximumAge:0,timeout: 10000});
            }
            else{
                this.$toast.warning('Debe Aceptar el Permiso de Localización Para Continuar');
                this.DisableBtn=false;
                this.Estatus='';
            }
        },
        Regresar(){
            this.$router.push({ name: "HomeWebApp", params: {} });
        },
        SetTipoCheck(Tipo){
            if (this.isButtonCheckInDisabled) {
                // const FinTiempo = new Date(new Date().getTime() + 120 * 1000);
                // this.$store.state.TiempoRestanteTimer=FinTiempo;
                // console.log(this.$store.state.TiempoRestanteTimer.getTime());
                // console.log(new Date().getTime());
                const FinTiempo=this.$store.state.TiempoRestanteTimer.getTime()-new Date().getTime();
                // console.log(FinTiempo);
                this.$store.state.TiempoRestanteTimer=new Date(new Date().getTime()+FinTiempo);
                // console.log(this.$store.state.TiempoRestanteTimer);
                this.$toast.warning('Debe Esperar '+this.tiempoRestanteCheckIn.Minutos+' Minutos Y '+this.tiempoRestanteCheckIn.Segundos+' Segundos Para Realizar La Siguiente Acción');
            }
            else{
                this.TipoCheck=Tipo;
                if (Tipo=='checkin') {
                    if (this.CheckOut) {
                        if (this.Servicios.length>0) {
                            this.$toast.warning('Debe Terminar Todos Sus Servicios Para Realizar Su Check-Out');
                            return false;
                        }
                    }
                    let DispMedia=navigator.mediaDevices;
                    if (!DispMedia || !DispMedia.getUserMedia) {
                        console.log("getUserMedia() not supported.");
                        return;
                    }
                    DispMedia.getUserMedia({
                        audio:false,
                        video:{
                            width: { min:340, ideal: 620, max:900 },
                            height: { min:750,ideal: 460, max:900 },
                            aspectRatio:{ideal:1.7777777778,},
                            // facingMode:{exact:"environment"},
                        }
                    }).then((VidStream)=>{
                        this.localstream=VidStream;
                        this.ShowCheckIn=false;
                        this.ShowBotones=false;
                        var video = document.getElementById('videoCam');
                        this.ShowCamara=true;
                        if ("srcObject" in video) {
                            video.srcObject = VidStream;
                        } else {
                            video.src = window.URL.createObjectURL(VidStream);
                        }
                        video.onloadedmetadata = function(e) {
                            video.play();
                        };
                    }).catch((e)=>{
                        console.log(e.name + ": " + e.message);
                    });
                }
                if (Tipo=='comida' || Tipo=='evento') {
                    let Site=0;
                    this.TipoCheck=Tipo=='evento' ? Tipo+this.ContadorEvento : Tipo;
                    let IndexLista=this.ListaChecks.findIndex((element)=>element.TipoCheck==this.TipoCheck);
                    let Index=IndexLista==-1 ? 0 : this.ListaChecks[IndexLista].IdCheck;
                    if (Tipo=='comida') {
                        this.ModalProps.Titulo=Index==0 ? "Registro de Comida" : "Registro de Check-Out Comida";
                    }
                    if (Tipo=='evento') {
                        if (Index==0) {
                            this.ModalProps.Titulo="Registro de Evento";
                        }
                        else{
                            if (this.ListaChecks[IndexLista].FechaSite==null) {
                                Site=1;
                                this.ModalProps.Titulo="Registro de Check Site Evento";
                            }
                            else{
                                this.ModalProps.Titulo="Registro de Check-Out Evento";
                            }
                        }
                        // this.ModalProps.Titulo=Index==0 ? "Registro de Evento" : "Registro de Check-Out Evento";
                    }
                    this.bus.$emit('Nuevo_'+this.ModalProps.EmitSeccion,this.IdTrabajador,this.TipoCheck,Index,Site);
                }
            }
        },
        AjustarCanvas(){
            let {width, height} = this.localstream.getVideoTracks()[0].getSettings();
            let canvas=document.getElementById("canvas");
            let video=document.getElementById("videoCam");
            video.setAttribute("width",width);
            video.setAttribute("height",height);
            const containerWidth = document.querySelector('.camera-container').offsetWidth;
            const containerHeight = document.querySelector('.camera-container').offsetHeight;
            canvas.setAttribute("width",containerWidth);
            canvas.setAttribute("height",containerHeight);
        },
        Cancelar(){
            this.$swal({
                title: 'Eliminar Foto',
                text: '¿Está seguro que quiere eliminar la foto?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$refs.photo.setAttribute("src","");
                    this.ShowCamara=false;
                    this.ShowConfirmacionFoto=false;
                    this.ShowComentario=false;
                    this.Comentario='';
                    this.TipoCheck=0;
                    this.Foto='';
                    this.Buscar();
                }
            })
        },
        TomarFoto(){
                let {width, height} = this.localstream.getVideoTracks()[0].getSettings();
                const containerWidth = document.querySelector('.camera-container').offsetWidth;
                const containerHeight = document.querySelector('.camera-container').offsetHeight;
                let canvas=document.getElementById("canvas");
                let video=document.getElementById("videoCam");
                let photo=document.getElementById("photo");
                const context=canvas.getContext("2d");
                canvas.width=containerWidth;
                canvas.height=containerHeight;
                video.pause();
                const visibleWidth = video.videoWidth * (containerWidth / video.videoHeight);
                const visibleHeight = video.videoHeight;
                const offsetX = (video.videoWidth - visibleWidth) / 2;
                const offsetY = (video.videoHeight - visibleHeight) / 2;
                context.drawImage(video,offsetX,offsetY,visibleWidth,visibleHeight,0, 0, containerWidth, containerHeight);
                photo.setAttribute("src",canvas.toDataURL("image/jpeg"));
                this.ShowTomarFoto=false;
                this.ShowConfirmacionFoto=true;
        },
        ConfirmarFoto(){
            this.ShowCamara=false;
            this.$refs.videoCam.src="";
            this.Comentario='';
            this.localstream.getTracks()[0].stop();
            this.ShowComentario=true;
            this.Foto=this.$refs.photo.getAttribute("src");
            if (!this.CheckInExtra) {
                this.EvaluarHoraExtra();
            }
        },
        EvaluarHoraExtra(){
            // RETARDO U HORA EXTRA
            let FechaActual=new Date();
            //FECHA FALSEADA PARA PRUEBAS
            // FechaActual.setDate(FechaActual.getDate()+1);
            // FechaActual.setHours(4,30,0,0);
            //FECHA REAL
            FechaActual.setHours(FechaActual.getHours(),FechaActual.getMinutes(),0,0);
            let HoraActual=FechaActual.getTime();
            if (!this.CheckOut) {
                let FechaEntrada=new Date();
                let PartesHoraEntrada=this.HoraEntrada.split(':');
                FechaEntrada.setHours(Number(PartesHoraEntrada[0]),Number(PartesHoraEntrada[1]),0,0);
                let MiliSecEntrada=FechaEntrada.getTime();
                if (HoraActual<MiliSecEntrada) {
                    let Diferencia=MiliSecEntrada-HoraActual;
                    let CantidadHoraExtra=parseInt(this.CantidadHoraExtra)+Math.floor(Diferencia/3600000);
                    if (CantidadHoraExtra>0) {
                        this.HoraExtra=true;
                    }
                    else{
                        this.HoraExtra=false;
                    }
                }
                else{
                    this.HoraExtra=false;
                }
            }
            else{
                let FechaSalida=new Date();
                let PartesHoraSalida=this.HoraSalida.split(':');
                FechaSalida.setHours(Number(PartesHoraSalida[0]),Number(PartesHoraSalida[1]),0,0);
                if (Number(this.HoraEntrada.split(':')[0])>Number(PartesHoraSalida[0])) {
                    FechaSalida.setDate(FechaSalida.getDate()+1);
                }
                let MiliSecSalida=FechaSalida.getTime();
                if (HoraActual>MiliSecSalida) {
                    let Diferencia=HoraActual-MiliSecSalida;
                    let CantidadHoraExtra=parseInt(this.CantidadHoraExtra)+Math.floor(Diferencia/3600000);
                    if (CantidadHoraExtra>0) {
                        this.HoraExtra=true;
                    }
                    else{
                        this.HoraExtra=false;
                    }
                }
                else{
                    this.HoraExtra=false;
                }
            }
            // if (!this.AutHoraExtra) {
            //     this.HoraExtraValor=0;
            //     return false;
            // }
            // let FechaProto=new Date();
            // let ActualCompleto = FechaProto.toLocaleString("es-MX", {
            //     hourCycle:"h24",
            //     hour:"numeric",
            //     minute:"numeric",
            //     second:"numeric",
            //     timeZone: sessionStorage.getItem("ZonaHoraria"),
            // });
            // let Actual=ActualCompleto.split(':');
            // let HoraActual=(parseInt(Actual[0])*60*60+parseInt(Actual[1])*60+0)*1000;
            // if (!this.CheckOut) {
            //     let HoraEntrada=this.HoraEntrada+':0';
            //     let PartesHoraEntrada=HoraEntrada.split(':');
            //     let MiliSecEntrada=(parseInt(PartesHoraEntrada[0])*60*60+parseInt(PartesHoraEntrada[1])*60+0)*1000;
            //     if (HoraActual<MiliSecEntrada) {
            //         this.HoraExtra=true;
            //         if (this.HoraExtraValor==0) {
            //             this.HoraExtraValor=1;
            //         }
            //     }
            //     else{
            //         this.HoraExtra=false;
            //     }
            // }
            // else{
            //     let PartesHoraSalida=this.HoraSalida.split(':');
            //     let MiliSecSalida=(parseInt(PartesHoraSalida[0])*60*60+parseInt(PartesHoraSalida[1])*60+0)*1000;
            //     if (HoraActual>MiliSecSalida) {
            //         this.HoraExtra=true;
            //         if (this.HoraExtraValor==0) {
            //             this.HoraExtraValor=1;
            //         }
            //     }
            //     else{
            //         this.HoraExtra=false;
            //     }
            // }
        },
        VolverATomarFoto(){
            this.ShowCamara=true;
            this.ShowTomarFoto=true;
            this.ShowConfirmacionFoto=false;
            this.$refs.videoCam.play();
            this.$refs.photo.setAttribute("src","");
        },
        PingServerGuardar(){
            this.DisableBtn=true;
            this.$http.get('webapp/ping',{
                timeout:1000
            }
            ).then(()=>{
                this.Guardar();
            }).catch((err)=>{
                // console.log(err);
                this.DisableBtn=false;
                this.$toast.warning('Ocurrió un Problema con la Red, Intentelo de Nuevo');
            })
        },
    },
    created() {
        this.bus.$off('Lista_'+this.ModalProps.EmitSeccion);
        this.bus.$on('Lista_'+this.ModalProps.EmitSeccion,()=>{
            this.Buscar();
        });
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        let Trabajador=JSON.parse(sessionStorage.getItem('user'))
        this.IdTrabajador=Trabajador.IdTrabajador;
        this.HoraEntrada=Trabajador.HoraEntrada+':'+Trabajador.MinutoEntrada;
        this.HoraSalida=Trabajador.HoraSalida+':'+Trabajador.MinutoSalida;
        this.Tolerancia=Trabajador.Tolerancia;
        // this.AutHoraExtra=Trabajador.AutHoraExtra=='0' ? false : true;
        this.ListServicios();
        window.setInterval(function () {
            // $('#reloj').html(moment().format('H:mm:ss'))
            let FechaProto=new Date();
            let Fecha = FechaProto.toLocaleString("es-MX", {
                hourCycle:"h24",
                hour:"2-digit",
                minute:"numeric",
                second:"numeric",
                timeZone: sessionStorage.getItem("ZonaHoraria")
            });
            $('#hora-check-in').html(Fecha);
        }, 1000);
    },
    mounted() {
    },
    computed: {
        ...mapGetters(['isButtonCheckInDisabled','tiempoRestanteCheckIn']),
        // Tempo(){
        //     if (this.isButtonCheckInDisabled) {
        //         window.setInterval(function () {
        //             $('#temporizador').html(this.tiempoRestanteCheckIn.Minutos+':'+this.tiempoRestanteCheckIn.Segundos)
        //         }, 1000);
        //     }
        // }
    },
}
</script>

<style scoped>
.photo-servicio-container{
    position: absolute;
    overflow: hidden;
    z-index:90;
    top:0;
    left:0;
    min-height: 100%;
    min-width: 100%;
}
.video-camera{
    position: relative;
    width: 100%;
    min-height:100% !important;
    object-fit:cover;
    margin-bottom: 0;
    padding-bottom: 0;
}
#tomar-foto{
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}
.confirmacion-photo{
    z-index:96
}
.btn-confirmar{
    position: absolute;
    width: 100%;
    left: 10vw;
    top: 75vh;
    right: 4vw;
}
.btn-rechazar{
    position: absolute;
    left: 60vw;
    right: 1vw;
    top: 75vh;
}
.cropp{
    position: relative;
    width:100%;
    height: 100%;
}
.cropp > img{ 
    max-width: 100vw;
    height: 40vh;
    object-fit: fill;
}
</style>