<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="row">
                    <div class="col-lg-6">
                        <label for="">Nombre *</label>
                        <input type="text" v-model="DocumentoCm.Nombre" placeholder="Nombre" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-6">
                        <label for="">Fecha *</label>
                        <v-date-picker v-model="DocumentoCm.Fecha" :mode="typeCalendar"
                            :popover="{
                                placement: 'bottom',
                                visibility: 'click'
                            }"
                            :input-props="{
                                class: 'form-control cal-black',
                                style: 'cursor:pointer;'
                            }"
                            :locale="'es-MX'"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label for="">Entregado A *</label>
                        <input type="text" v-model="DocumentoCm.EntregadoA" placeholder="Entregado A" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.EntregadoA" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-6">
                        <label for="">Posición *</label>
                        <input type="text" v-model="DocumentoCm.Posicion" placeholder="Posición" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Posicion" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-12">
                        <label for="">Documento *</label>
                        <div class="custom-file-input-image">
                            <input @change="SubirArchivo()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                            <input type="text" v-model="Archivo" class="form-control">
                            <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label for="">Descripción</label>
                        <textarea cols="30" rows="1" v-model="DocumentoCm.Descripcion" class="form-control" placeholder="Descripción"></textarea>
                    </div>
                </div>
            </template>
        </CLoader>
    </div>
</template>

<script>
export default {
    name:"Form",
    props:["poBtnSave","pIdClienteS","pContacto"],
    data() {
        return {
            Modal:true,
            FormName:'DocumentosCm',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            },
            DocumentoCm:{
                IdDocumentoCm:0,
                IdCliente:0,
                IdClienteS:0,
                Nombre:'',
                Descripcion:'',
                Fecha:'',
                EntregadoA:'',
                Posicion:'',
                Archivo:'',
            },
            Archivo:'Elegir Archivo (3MB)',
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            this.errorvalidacion=[];
            let Fecha='';
            if (this.DocumentoCm.Fecha!='' && this.DocumentoCm.Fecha!=undefined) {
                let day=this.DocumentoCm.Fecha.getDate();
                let month=this.DocumentoCm.Fecha.getMonth()+1;
                let year=this.DocumentoCm.Fecha.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
            let file = this.$refs.file.files[0];
            let formData=new FormData();
            formData.set('IdDocumentoCm',this.DocumentoCm.IdDocumentoCm);
            formData.set('IdCliente',this.DocumentoCm.IdCliente);
            formData.set('IdClienteS',this.DocumentoCm.IdClienteS);
            formData.set('Nombre',this.DocumentoCm.Nombre);
            formData.set('Descripcion',this.DocumentoCm.Descripcion);
            formData.set('Fecha',Fecha);
            formData.set('EntregadoA',this.DocumentoCm.EntregadoA);
            formData.set('Posicion',this.DocumentoCm.Posicion);
            formData.set('NombreArchivo',this.DocumentoCm.Archivo);
            formData.append('NombreArchivoFile',file);
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('documentoscm/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $("#DocumentoCm").modal("hide");
                this.bus.$emit('List',"Crear","DocumentoCm");
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            })
        },
        Limpiar(){
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';
            this.DocumentoCm={
                IdDocumentoCm:0,
                IdCliente:0,
                IdClienteS:0,
                Nombre:'',
                Descripcion:'',
                Fecha:new Date(),
                EntregadoA:'',
                Posicion:'',
                Archivo:'',
            }
            // if (localStorage.getItem("fechacalendario")) {
			// 	let NuevaFecha = localStorage.getItem("fechacalendario");
			// 	let formatedDate = NuevaFecha.replace(/-/g, "\/");
			// 	this.DocumentoCm.Fecha = new Date(formatedDate);
			// }
            this.Archivo='Elegir Archivo (3MB)';
            this.errorvalidacion=[];
        },
        Recuperar(){
            this.$http.get('documentoscm/recovery',{
                params:{
                    IdDocumentoCm:this.DocumentoCm.IdDocumentoCm
                }
            }).then((res)=>{
                const objres=res.data.data.documentocm;
                this.DocumentoCm.IdDocumentoCm=objres.IdDocumentoCm;
                this.DocumentoCm.IdCliente=objres.IdCliente;
                this.DocumentoCm.IdClienteS=objres.IdClienteS;
                this.DocumentoCm.Nombre=objres.Nombre;
                this.DocumentoCm.Descripcion=objres.Descripcion;
                let formatedDate = objres.Fecha.replace(/-/g, "\/");
                this.DocumentoCm.Fecha=new Date(formatedDate);
                this.DocumentoCm.EntregadoA=objres.EntregadoA;
                this.DocumentoCm.Posicion=objres.Posicion;
                this.DocumentoCm.Archivo=objres.Archivo;
                this.Archivo=objres.Archivo;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            })
        },
        SubirArchivo(){
            const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 3) {
                this.$toast.info('Solo se puede subir archivos menores a 3 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extenciones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.Archivo='Elegir archivo (3MB)';
                return false;
            }

            this.Archivo=image.name;
        }
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id,Propiedad,IdClienteS)=>{
            this.ConfigLoad.ShowLoader=true;
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (Propiedad!==undefined) {
                this.DocumentoCm.IdCliente=Propiedad.IdCliente;
            }
            if (IdClienteS!==undefined) {
                this.DocumentoCm.IdClienteS=IdClienteS;
            }
            if (Id > 0) {
				this.DocumentoCm.IdDocumentoCm = Id;
				this.Recuperar();
			}
            else{
                this.ConfigLoad.ShowLoader=false;
            }
        });
    },
    computed:{
        typeCalendar:function(){
            return 'single';
        }
    }
}
</script>

<style>

</style>